import React, { useState } from "react";
import { Customer } from "../../helpers/Responses";
import "twin.macro";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import Button from "../../components/Button";
import Api from "../../helpers/Api";
import { useQueryClient } from "react-query";

interface CustomerDetailProps {
  customer?: Customer | null;
  onSave?: () => void;
}

export default function CustomerDetail(props: CustomerDetailProps) {
  const queryClient = useQueryClient();
  const { state } = useContext(AppContext);
  const { customer } = props;
  const [value, setValue] = useState<Record<string, boolean>>(() => {
    let map: Record<string, boolean> = {};
    customer?.groups?.forEach(g => map[g.id] = true)
    return map;
  });
  const [loading, setLoading] = useState(false);

  const groups = state.options.customerGroups

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { value, checked } = event.target;
    console.log(event.target);
    setValue(v => ({ ...v, [value]: checked }))
  }

  async function handleSave() {
    if (!customer) return;
    setLoading(true);

    let groups = Object.entries(value).filter(([k, v]) => v).map(([k]) => ({ id: k }));

    let res = await Api.updateCustomer(customer.id, { groups })
    setLoading(false);
    if (res.status === 200) {
      queryClient.invalidateQueries('listCustomers');
      props.onSave && props.onSave()
    }
  }

  if (!customer) return null;

  return (
    <div>
      <table>
        <tr>
          <td tw="font-semibold pr-2">Name</td>
          <td>{customer.name}</td>
        </tr>
        <tr>
          <td tw="font-semibold pr-2">Mobile</td>
          <td>{customer.mobile}</td>
        </tr>
        <tr>
          <td tw="font-semibold pr-2">Email</td>
          <td>{customer.email}</td>
        </tr>
      </table>
      <hr tw="my-2 border-gray-300" />
      <h3 tw="font-semibold">Customer Groups</h3>
      <p tw="text-sm text-gray-500">Pricing applied to group will be applicable for the user.</p>
      {groups?.map(g => (
        <label key={g.value} tw="block my-2">
          <input
            type="checkbox"
            value={g.value}
            onChange={handleChange}
            checked={value[g.value]}
          />
          <span tw="ml-2">{g.label}</span>
        </label>
      ))}
      <div tw="flex justify-end">
        <Button onClick={props.onSave} variant="secondary">Cancel</Button>
        <Button onClick={handleSave} loading={loading}>Save</Button>
      </div>
    </div>
  )
}