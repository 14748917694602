import React, { useContext } from 'react';
import Input from '../../components/Input';
import styled from 'styled-components';
import Select from '../../components/Select';
import { AppContext } from '../../context/AppContext';
import "twin.macro";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

export interface OrderFilterParams {
  search: string;
  status: string;
  payStatus: string;
  delStatus: string;
}

interface Props {
  params?: OrderFilterParams;
  onChange?: (value: OrderFilterParams) => void;
}

const allOption = { label: 'All', value: 'all' }

const Wrapper = styled.div`
  flex: 0 1 150px;
  min-width: 120px;
`;

export default function OrdersFilterPane(props: Props) {
  const { params, onChange } = props;
  const { state } = useContext(AppContext);
  const options = [allOption, ...state.options.orderStatus]
  const payOptions = [allOption, ...state.options.payStatus]
  const delOptions = [allOption, ...state.options.delStatus]

  function handleChangeEvent(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    handleChange(event.target.value, event.target.name);
  }

  function handleChange(value: string | number | undefined, name: string) {
    let p = params || {} as OrderFilterParams;
    value = value ? value.toString() : '';
    onChange && onChange({ ...p, [name]: value + '' })
  }

  return (
    <Container tw="space-x-2">
      <Input
        name="search"
        label="Search"
        placeholder="Search (Name or Mobile)"
        style={{ flex: '0 1 500px', marginRight: 'auto' }}
        value={params?.search}
        onChange={handleChangeEvent}
      />
      <Wrapper>
        <Select
          name="status"
          label="Order Status"
          options={options}
          value={params?.status}
          onChange={handleChangeEvent}
        />
      </Wrapper>
      <Wrapper>
        <Select
          name="payStatus"
          label="Payment Status"
          options={payOptions}
          value={params?.payStatus}
          onChange={handleChangeEvent}
        />
      </Wrapper>
      <Wrapper>
        <Select
          name="delStatus"
          label="Delivery Status"
          options={delOptions}
          value={params?.delStatus}
          onChange={handleChangeEvent}
        />
      </Wrapper>
    </Container>
  )
}