import React from 'react';
import { Order } from '../../../helpers/Responses';
import styled from 'styled-components';
import moment from 'moment';
import { AddressView } from '../../../components/AppComponents';
import "twin.macro"

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  h5 {
    margin-bottom: 0
  }
  h5 + p {
    margin-top: 0
  }
`;
Container.displayName = 'OrderHeadContainer';


interface Props {
  order: Order;
}

export function OrderHead(props: Props) {
  const { order } = props;

  return (
    <Container>
      <div style={{ maxWidth: "60%" }}>
        <p>{order.shippingDetails.name}</p>
        <p>{order.shippingDetails.mobile}</p>
        <p>{order.shippingDetails.email}</p>
        <AddressView data={order.shippingDetails.address} style={{ marginBottom: '16px', fontSize: '14px' }} />
      </div>
      <div style={{ textAlign: "right" }}>
        <p tw="font-bold text-xl mb-2">Order #{order.no}</p>
        <p tw="font-semibold text-gray-700 text-xs">Ordered At</p>
        <p tw="mb-2">{moment(order.createdAt).format('DD MMM YYYY, hh:mm A')}</p>
        <p tw="font-semibold text-gray-700 text-xs">Expected Delivery</p>
        {Array.isArray(order.shippingPlan) &&
          order.shippingPlan.map((plan) => (
            <p key={plan.id}>
              {moment(plan.eta.date).format("DD MMM YYYY")}
              <span tw="text-xs inline-block ml-1">({plan.name})</span>
            </p>
          ))}
      </div>
    </Container>
  )
}