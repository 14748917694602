import React, { useReducer } from 'react';
import { Option } from '../components/Select';
import { ConfigAllResponse } from '../helpers/Responses';
import { slugToOptions, slugToMap, toOptions, toMap } from '../helpers/Utils';

interface Action {
  type: string;
  payload?: any;
}

interface AppState {
  configs: ConfigAllResponse;
  options: {
    orderStatus: Option[],
    delStatus: Option[],
    payStatus: Option[],
    customerGroups: Option[],
    conditionalShippingPlans: Option[],
  },
  map: {
    orderStatus: Record<string, string>,
    delStatus: Record<string, string>,
    payStatus: Record<string, string>,
    customerGroups: Record<string, string>,
  }
}

const initialState: AppState = {
  configs: {
    banners: [],
    shareMsg: '',
    customerGroups: [],
    order: {
      orderStatus: [],
      delStatus: [],
      payStatus: []
    }
  },
  options: {
    orderStatus: [],
    delStatus: [],
    payStatus: [],
    customerGroups: [],
    conditionalShippingPlans: [],
  },
  map: {
    orderStatus: {},
    delStatus: {},
    payStatus: {},
    customerGroups: {},
  }
}

const initialContextValue = {
  state: initialState,
  // dispatch: (action: Action) => { },
  dispatch: (type: string, payload?: any) => { },
}

export const AppContext = React.createContext(initialContextValue);

export const AppActions = {
  LoadConfig: 'loadConfig',
  UpdateBanners: 'updateBanners',
}

function reducer(state: AppState, action: Action) {
  switch (action.type) {
    case AppActions.LoadConfig:
      let configs = action.payload;
      let options: AppState['options'] = {
        orderStatus: slugToOptions(configs.order.orderStatus).filter(o => o.value !== 'cancelled'),
        delStatus: slugToOptions(configs.order.delStatus),
        payStatus: slugToOptions(configs.order.payStatus),
        customerGroups: toOptions(configs.customerGroups),
        conditionalShippingPlans: toOptions(configs.conditionalShippingPlans, { blank: ' - Default Plan - ' }),
      }
      let map = {
        orderStatus: slugToMap(configs.order.orderStatus),
        delStatus: slugToMap(configs.order.delStatus),
        payStatus: slugToMap(configs.order.payStatus),
        customerGroups: toMap(configs.customerGroups),
      }
      return { ...state, configs, options, map }
    case AppActions.UpdateBanners:
      let banners = action.payload;
      return { ...state, configs: { ...state.configs, banners } }
  }
  return state;
}

export function AppContextProvider(props: React.PropsWithChildren<{}>) {
  let [state, dispatch] = useReducer(reducer, initialState)

  const dispatcher = (type: string, payload?: any) => dispatch({ type, payload })

  // const value = React.useMemo(() => [state, dispatch], [state])
  const value = { state, dispatch: dispatcher }
  return (
    <AppContext.Provider value={value}>
      {props.children}
    </AppContext.Provider>
  )
}

