import styled from "styled-components";

/* Wrapper */

export const AppWrapper = styled.div`
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #eee;
`;

/* Layout */

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type FlexAlignType = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';

export type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';

export interface FlexStyle {
  alignContent?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'space-between' | 'space-around';
  alignItems?: FlexAlignType;
  alignSelf?: 'auto' | FlexAlignType;
  flex?: number;
  flexBasis?: number | string;
  flexDirection?: FlexDirection;
  flexGrow?: number;
  flexShrink?: number;
  flexWrap?: 'wrap' | 'nowrap' | 'wrap-reverse';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
}

interface FlexProps extends FlexStyle {
  between?: boolean;
  center?: boolean;
  column?: boolean;
}

export const Flex = styled.div<FlexProps>`
  display: flex;
  ${p => `
    ${p.between ? `
      justify-content: space-between;
      align-items: center;  
    ` : ''}
    ${p.center ? `
      justify-content: center;
      align-items: center;
      height: 100%;
    ` : ''}
    ${p.column ? `flex-direction: column;` : ''}
    ${p.flexDirection ? `flex-direction: ${p.flexDirection};` : ''}
    ${p.justifyContent ? `justify-content: ${p.justifyContent};` : ''}
    ${p.alignItems ? `align-items: ${p.alignItems};` : ''}
    ${p.alignSelf ? `align-self: ${p.alignSelf};` : ''}
    ${p.alignContent ? `align-content: ${p.alignContent};` : ''}
    ${p.flex ? `flex: ${p.flex};` : ''}
    ${p.flexBasis ? `flex-basis: ${p.flexBasis};` : ''}
    ${p.flexGrow ? `flex-grow: ${p.flexGrow};` : ''}
    ${p.flexShrink ? `flex-shrink: ${p.flexShrink};` : ''}
    ${p.flexWrap ? `flex-wrap: ${p.flexWrap};` : ''}
  `}
`;

Flex.displayName = 'Flex';
