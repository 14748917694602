import axios, { AxiosInstance, AxiosResponse } from "axios";
import {
  EmptyResponse,
  LoginResponse,
  ProductListResponse,
  ProductListParams,
  OrderListResponse,
  OrderListParams,
  OrderStatusSet,
  OrderEditPayload,
  UploadPurpose,
  UploadResponse,
  Product,
  CategoriesResponse,
  ConfigAllResponse,
  BannerItem,
  RouteItem,
  AreaItem,
  DashboardReport,
  QuickLoginRequest,
  OTPRequest,
  OTPPurpose,
  CustomerProfile,
  OrderPreparePayload,
  Order,
  OrderCreatePayload,
  Customer,
} from "./Responses";
import DataStore from "./DataStore";
import { toast } from "react-toastify";
import { QueryFunctionContext, QueryKey } from "react-query";

const baseURL =
  process.env.REACT_APP_BASE_API || process.env.NEXT_PUBLIC_BASE_API;

const timeoutErrorMessage =
  "Server took long time to respond. Please try again later";

const OPEN_URLS = ["/otp", "/merchants/login", "/merchants/forgot-password"];

type CommonMasterRoutes =
  | "routes"
  | "areas"
  | "salt"
  | "manufacturers"
  | "categories"
  | "tax-groups"
  | "ledgers";

function onFulfilled(value: AxiosResponse<any>) {
  return value;
}

function onRejected(error: any) {
  const { status = -1, data } = error.response || {};
  if (status === 401) {
    DataStore.clearAll();
    if (window.location.pathname !== "/") window.location.href = "/";
  } else if (status >= 400) {
    toast.error(
      (data && data.message) || "Server error. Please try again later"
    );
  } else if (status === -1) {
    if (typeof window === "undefined") {
      console.error("Please check network calls to api");
    } else {
      if (navigator.onLine)
        toast.error("Please check your internet connection", {
          toastId: "interceptor-default",
        });
      else
        toast.error("You're offline. Please connect to internet", {
          toastId: "interceptor-default",
        });
    }
  }

  return Promise.reject(error);
}

class ApiService {
  private tenant?: string;
  private token?: string | null;

  http: AxiosInstance;

  constructor(tenant?: string, token?: string) {
    if (tenant) this.tenant = tenant;
    if (token) this.token = token;
    const _this = this;

    this.http = axios.create({ baseURL, timeout: 8000, timeoutErrorMessage });

    this.http.interceptors.request.use(function onFulfilled(config) {
      if (config.url && !OPEN_URLS.includes(config.url)) {
        const token = _this.getToken();
        if (token) config.headers["Authorization"] = `Bearer ${token}`;
        config.headers["X-Tenant"] = _this.getTenant();
      }
      return config;
    });

    this.http.interceptors.response.use(onFulfilled, onRejected);
  }

  private getTenant() {
    if (this.tenant) return this.tenant;
    if (typeof window !== "undefined") this.tenant = DataStore.getTenant();
    return this.tenant;
  }

  private getToken() {
    // if (this.token) return this.token;
    if (typeof window !== "undefined") this.token = DataStore.getToken();
    return this.token;
  }

  withTenant(tenant: string, token?: string) {
    return new ApiService(tenant, token);
  }

  static getInstance() {
    return new ApiService();
  }

  // auth service

  quickLogin = (payload: QuickLoginRequest) =>
    this.http.post<LoginResponse>("/customers/quick-login", payload);

  login = (email: string, password: string) =>
    this.http.post<LoginResponse>("/merchants/login", {
      email,
      password,
      tenant: this.getTenant(),
    });

  customerLogout = () => this.http.post("/customers/logout");

  generateOTP = (payload: OTPRequest, purpose: OTPPurpose) =>
    this.http.post<EmptyResponse>(`/otp?purpose=${purpose}`, payload);

  getCustomerProfile = () =>
    this.http.get<CustomerProfile>("/customers/profile");

  updateCustomerProfile = (profile: CustomerProfile) =>
    this.http.post<EmptyResponse>("/customers/profile", profile);

  // merchant: customers
  listCustomers = () => this.http.get<CustomerProfile[]>("/merchants/customers");

  updateCustomer = (id: string, data: Partial<Customer>) =>
    this.http.patch<EmptyResponse[]>(`/merchants/customers/${id}`, data);

  // merchant: salesmen
  listEmployees = () => this.http.get("/merchants/employees?role=salesmen");
  createEmployee = (data: any) =>
    this.http.post("/merchants/employees/", { ...data, role: "salesmen" });
  updateEmployee = (id: string, data: any) =>
    this.http.put(`/merchants/employees/${id}`, data);
  deleteEmployee = (id: string) =>
    this.http.delete(`/merchants/employees/${id}`);

  // merchant: suppliers
  listSuppliers = () => this.http.get("/merchants/suppliers");
  createSupplier = (data: any) => this.http.post("/merchants/suppliers/", data);
  updateSupplier = (id: string, data: any) =>
    this.http.put(`/merchants/suppliers/${id}`, data);
  deleteSupplier = (id: string) =>
    this.http.delete(`/merchants/suppliers/${id}`);

  // upload service
  upload = (data: FormData, purpose: UploadPurpose) =>
    this.http.post<UploadResponse>("/upload", data, {
      headers: { "Content-Type": "multipart/form-data" },
      params: { purpose },
    });

  // product service
  listProducts = (params?: ProductListParams) =>
    this.http.get<ProductListResponse>("/products", { params });

  getProduct = (id: string) => this.http.get<Product>("/products/" + id);

  createProduct = (data: Product) => this.http.post<Product>("/products", data);

  editProduct = (id: string, data: Product) =>
    this.http.put<EmptyResponse>("/products/" + id, data);

  deleteProduct = (id: string) =>
    this.http.delete<EmptyResponse>("/products/" + id);
  // deleteProduct = (id: string) => Promise.resolve(),

  bulkProducts = (data: FormData) =>
    this.http.post<UploadResponse>("/products-bulk", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });

  // order service
  prepareOrder = (data: OrderPreparePayload) =>
    this.http.post<Order>("/orders/prepare", data);

  createOrder = (data: OrderCreatePayload) =>
    this.http.post<Order>("/orders", data);

  listOrders = (params?: OrderListParams) =>
    this.http.get<OrderListResponse>("/orders", { params });

  getOrderDetails = (id: string) => this.http.get<Order>(`/orders/${id}`);

  updateOrderStatus = (id: string, status: OrderStatusSet) =>
    this.http.patch<EmptyResponse>("/orders/" + id, status);

  editOrder = (id: string, data: OrderEditPayload) =>
    this.http.put<EmptyResponse>(`/orders/${id}`, data);

  // config service
  Masters = <T = any>(routePath: CommonMasterRoutes) => ({
    list: () => this.http.get<T>(`/configs/${routePath}`),
    create: (data: any) => this.http.post(`/configs/${routePath}`, data),
    update: (id: string, data: any) =>
      this.http.put(`/configs/${routePath}/${id}`, data),
    delete: (id: string) => this.http.delete(`/configs/${routePath}/${id}`),
  });

  getCategories = () => this.Masters<CategoriesResponse>("categories").list();
  // getCategories = () => this.http.get<CategoriesResponse>('/configs/categories')

  getAreas = () => this.Masters<Array<AreaItem>>("areas").list();

  getRoutes = () => this.Masters<Array<RouteItem>>("routes").list();

  getConfig = () => this.http.get<ConfigAllResponse>("/configs/all");

  updateBanner = (data: BannerItem[]) =>
    this.http.put<BannerItem[]>("/configs/banners", data);

  // Dashboard Reports
  getDashboard = () => this.http.get<DashboardReport>("/reports");
}

// for 'Browser'
const Api = ApiService.getInstance();

export type ApiName = Exclude<keyof ApiService, "withTenant" | "http">;

export type ApiParams<T extends ApiName> = Parameters<ApiService[T]>;

// export type ApiQueryKey<T extends ApiName> = [T, ...ApiParams<T>];

export declare type Fetcher<T = any, TQueryKey extends QueryKey = QueryKey> = (
  context: QueryFunctionContext<TQueryKey>
) => T | Promise<T>;

export const fetcher: Fetcher = ({ queryKey }) => {
  let [name, ...params] = queryKey;
  // @ts-ignore
  return Api[name](...params).then((res) => res.data);
};

export const fetcherWithTenant =
  (tenant: string): Fetcher =>
    ({ queryKey }) => {
      let [name, ...params] = queryKey;
      let api = Api.withTenant(tenant);
      // @ts-ignore
      return api[name](...params).then((res) => res.data);
    };

export default Api;
