import React from 'react';
import Login from './routes/Login';
import AppLayout from './routes/AppLayout';
import './loading.min.css'
import './ldbtn.min.css'
import styled, { ThemeProvider } from 'styled-components';
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import { RecoilRoot } from 'recoil';
import DataStore from './helpers/DataStore';
import { AppContextProvider } from './context/AppContext';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { Routes } from './helpers/Constants';
import { theme } from './theme';
import { ModalProvider } from './components/Modal/ModalProvider';
import { AppWrapper } from './components/Views';
import GlobalStyles from './GlobalStyles';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useEffect } from 'react';

const EnvBanner = styled.div`
  display: none;

  background: red;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 4px;
  text-align: center;
  position: absolute;
  transform: rotate(-45deg);
  width: 72px;
  margin-top: 28.5px; // 80/2 - 23/2
  margin-bottom: 28.5px; // 80/2 - 23/2
  left: -20px;
  top: -20px;
  box-shadow: 0px 1px 2px white;
`;

const isLoggedIn = () => !!DataStore.getToken();

const queryClient = new QueryClient();

function App() {
  const location = useLocation()

  useEffect(() => {
    // favicon
    let node = document.head.querySelector('link[rel=icon]');
    if (!node) {
      node = document.createElement("link");
      node.setAttribute('rel', 'icon')
      document.head.appendChild(node)
    }
    node.setAttribute('href', '/temp/logo.png')
  }, [])

  if (isLoggedIn() && location.pathname === '/') {
    return <Redirect to={{ pathname: Routes.Dashboard }} />
  } else if (!isLoggedIn() && location.pathname !== Routes.Login) {
    return <Redirect to={{ pathname: Routes.Login }} />
  }

  return (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider>
        <ThemeProvider theme={theme}>
          <ModalProvider>
            <RecoilRoot>
              <GlobalStyles />
              {process.env.REACT_APP_ENV !== 'prod' && (
                <EnvBanner>{process.env.REACT_APP_ENV}</EnvBanner>
              )}
              <AppWrapper>
                <Switch>
                  <Route path={Routes.Login} component={Login} />
                  <Route component={AppLayout} />
                </Switch>
                <ToastContainer autoClose={3000} hideProgressBar />
              </AppWrapper>
            </RecoilRoot>
          </ModalProvider>
        </ThemeProvider>
      </AppContextProvider>
    </QueryClientProvider>
  );
}

export default App;
