import styled from "styled-components";
import React from "react";
import "../../ldbtn.min.css";
import "../../loading.min.css";

// $primary: #2684ff;
// $primary-outline: rgba(38, 132, 255, 0.4);
// $danger: #cb2431;
// $danger-outline: rgba(203, 36, 49, 0.4);
// $secondary: #e8e8e8;
// $secondary-outline: rgba(100, 100, 100, 0.3);

export interface ButtonFieldProps {
  variant?: "primary" | "secondary" | "danger" | "link";
  size?: "medium" | "small";
}

const ButtonField = styled.button<ButtonFieldProps>`
  height: 38px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  font-size: 16px;
  cursor: pointer;
  padding: 2px 16px;
  box-sizing: border-box;
  transition: all 100ms;
  color: white;
  ${p => p.size === "small" && `
    height: 28px;
    font-size: 0.85em;
    padding: 2px 12px;
  `}
  ${p => `
    border-color: ${p.theme.primary};
    background-color: ${p.theme.primary};
  `}
  ${p => p.variant === "secondary" && `
    background-color: ${p.theme.secondary};
    border-color: ${p.theme.secondary};
    color: #333;
  `}
  ${p => p.variant === "danger" && `
    background-color: ${p.theme.danger};
    border-color: ${p.theme.danger};
  `}
  ${p => p.variant === "link" && `
    background-color: transparent;
    border: none;
    color: #2929d6;
    font-size: 14px;
  `}

  &:hover,
  &:focus {
    outline: 0 !important;
    ${p => p.theme.button.focus[p.variant || 'primary']}
     &.running {
      box-shadow: none;
      cursor: progress;
    }
  }

  &[disabled] {
    background: #ddd;
    color: #aaa;
    border: #ccc;
    box-shadow: none;
  }

  & + & {
    margin-left: 16px;
  }
`;
ButtonField.displayName = "ButtonField";

ButtonField.defaultProps = {
  type: 'button',
  variant: 'primary'
};

export interface Props extends ButtonFieldProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean
}

export default function Button(props: Props) {
  let { children, loading, disabled, className, onClick, ...rest } = props
  if (loading) {
    onClick = undefined
    className = (className || '') + ' ld-ext-right running'
    children = (
      <>
        {children}
        <div className="ld ld-ring ld-spin" style={{ animationDuration: "0.6s" }}></div>
      </>
    )
  }

  return (
    <ButtonField {...rest} className={className} onClick={onClick} disabled={loading || disabled}>
      {children}
    </ButtonField>
  )
}

export const StyledButton = styled(Button)``;