import React, { useContext, useEffect, useState } from 'react';
import PageTitleContainer from '../../components/PageTitleContainer';
import { PageTitle, Label } from '../../components/Typo';
import Button from '../../components/Button';
import { Formik, Form, FieldArray } from 'formik';
// import Input from '../../components/Input';
import Input from '../../components/FormikField/Input';
import TextArea from '../../components/FormikField/TextArea';
import Select, { BooleanSelect } from '../../components/FormikField/Select';
import styled from 'styled-components';
import api from '../../helpers/Api';
import { useHistory } from 'react-router-dom';
import { Product, Variant } from '../../helpers/Responses';
import FileUpload from '../../components/FormikField/FileUpload';
import DataStore from '../../helpers/DataStore';
import { findDuplicateIndex, toOptions } from '../../helpers/Utils';
import Ajv from 'ajv';
import { productSchema } from '../../helpers/Schemas';
import { MultiError, validation } from '../../helpers/Validation';
import set from 'lodash/set';
import get from 'lodash/get';
import { toast } from 'react-toastify'
import Api from '../../helpers/Api';
import { Routes } from '../../helpers/Constants';
import { Option } from '../../components/Select';
import tw from "twin.macro";
import { AppContext } from '../../context/AppContext';

const ajv = new Ajv({ allErrors: true });
const validateProduct = ajv.compile(productSchema);

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

const emptyVariant: DeepPartial<Variant> = {
  sku: '',
  variantName: '',
  packing: '',
  unit: '', // remove this once freetext -> dropdown
  packs: [],
  mrp: undefined,
  price: undefined,
  purchaseRate: undefined,
  cost: undefined,
  status: 'active',
}

const initialValues: DeepPartial<Product> = {
  name: '',
  // code: '',
  desc: '',
  category: '',
  images: [],
  properties: {},
  variants: [
    { ...emptyVariant }
  ],
  visible: true,
}

const errorMessages: MultiError = {
  name: { default: 'Name is required' },
  // code: 'Code is required',
  category: 'Category is required',
  variants: [
    {
      sku: { default: 'SKU is required', duplicate: 'SKU must be unique' },
      mrp: { minimum: 'M.R.P. cannot be negative' },
      price: { default: 'Price is required', minimum: 'Price cannot be negative' }
    }
  ]
}

type FieldWrapperProps = { col?: number }

const FieldWrapper = styled.div<FieldWrapperProps>`
  ${p => p.col && `flex: 1 1 ${p.col}% !important;`}
`;

// const FieldWrapper = styled.div`
//   display: flex;
//   & > ${Label} {
//     padding-top: 6px;
//     flex: 1;
//   }
//   & > ${Label} + div {
//     flex: 2;
//   }
// `;

const UpperContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;

  ${FieldWrapper} {
    flex: 1 1 50%;
    box-sizing: border-box;
    margin-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
  
    // &:nth-child(2n) {
    //   padding-left: 16px;
    // }
    // &:nth-child(2n+1) {
    //   padding-right: 16px;
    // }  

    :last-child {
      padding-right: 0;
    }
  }
`;

const GroupContainerWrapper = styled.div`
  position: relative;
  border: 1px solid #bbb;
  border-radius: 2px;
  padding: 10px 8px 8px 8px;
  margin: 8px 0;

  & > ${Label} {
    position: absolute;
    top: -9px;
    background: #eee;
    border-radius: 6px;
    margin: 0;
    padding: 0 4px;
    font-size: 12px;
    font-weight: 500;
    color: #777;
  }
`;

function GroupContainer(props: React.PropsWithChildren<{ label: string, className?: string }>) {
  return (
    <GroupContainerWrapper className={props.className}>
      <Label>{props.label}</Label>
      {props.children}
    </GroupContainerWrapper>
  )
}

const VariantContainer = styled.div`
  ${FieldWrapper} {
    margin-right: 8px;
    flex: 1;
  
    :last-child {
      margin-right: 0;
    }
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 8px;

  &.header {
    align-items: flex-end;
    margin: 0;

    button {
      visibility: hidden
    }
  }
`;

export default function CreateProduct() {
  const { state } = useContext(AppContext)
  const categoriesOptions = toOptions(DataStore.getCategories());
  categoriesOptions.unshift({ label: '-- select --', value: '' });
  const manufacturersOptions = toOptions(DataStore.getManufacturers(), { blank: true });
  const taxGroupsOptions = toOptions(DataStore.getTaxGroups(), { blank: true });
  const saltGroupsOptions = toOptions(DataStore.getSaltGroups(), { blank: true });

  const history = useHistory()

  // const groups = state.configs.customerGroups;
  const { landingZones, conditionalShippingPlans } = state.configs;

  const pathId = history.location.pathname.split('/').pop();
  const id = pathId !== "new" ? pathId : undefined;

  let _product = history.location?.state as Product;
  let [product, setProduct] = useState(_product)

  useEffect(() => {
    if (id && !product) {
      Api.getProduct(id).then(res => setProduct(res.data))
    }
  }, [id, product])

  async function handleSubmit(values: Product) {
    // values.code = values.variants[0].sku;
    // console.log('submit values', values);

    if (id) {
      await api.editProduct(id, values)
      toast.success(`Item "${values.name}" updated`)
    } else {
      await api.createProduct(values)
      toast.success(`Item "${values.name}" created`)
    }
    history.push(Routes.Products);
  }

  function handleValidation(values: Product) {
    const errors = validation(validateProduct, values, errorMessages);

    const skus = values.variants.map(vr => vr.sku);
    const dup = findDuplicateIndex(skus);
    if (dup !== -1) {
      set(errors, `variants[${dup}].sku`, get(errorMessages, `variants[0].sku.duplicate`))
    }

    return errors;
  }

  return (
    <Formik initialValues={product || initialValues} enableReinitialize onSubmit={handleSubmit} validate={handleValidation}>
      {(props) => (
        <div style={{ maxWidth: '800px' }}>
          <Form>
            <PageTitleContainer>
              <PageTitle>
                {id ? `Edit Item: ${product ? product.name : ''}` : 'Create Item'}
              </PageTitle>
              <div>
                <Button variant="secondary" onClick={history.goBack}>Cancel</Button>
                <Button type="submit" loading={props.isSubmitting}>
                  {id ? 'Update' : 'Create'}
                </Button>
              </div>
            </PageTitleContainer>
            {/* <UpperContainer>
              <FieldWrapper>
                <Select name="variants[0].status" label="Status" layout="horizontal" options={[{ label: 'Continue', value: 'active' }, { label: 'Closed', value: 'inactive' }]} />
              </FieldWrapper>
              <FieldWrapper>
                <Input name="variants[0].sku" label="Item Code" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input name="name" label="Item Name" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Packing" name="variants[0].packing" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Unit 1" name="variants[0].unit" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Unit 2" name="variants[0].packs[0].label" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Conversion" name="variants[0].packs[0].conversion" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <BooleanSelect
                  name="decimalQty"
                  label="Decimal"
                  layout="horizontal"
                />
              </FieldWrapper>
              <FieldWrapper>
                <Select label="Company" name="properties.manufacturer" layout="horizontal" options={manufacturersOptions} />
              </FieldWrapper>
              <FieldWrapper>
                <Select label="Group/Salt" name="properties.salt" layout="horizontal" options={saltGroupsOptions} />
              </FieldWrapper>
              <FieldWrapper>
                <Select label="Category" name="category" layout="horizontal" options={categoriesOptions} />
              </FieldWrapper>
              <FieldWrapper>
                <Select label="HSN/SAC" name="variants[0].hsn" layout="horizontal" options={taxGroupsOptions} />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="M.R.P" name="variants[0].mrp" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Purchase Rate" name="variants[0].purchaseRate" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Cost" name="variants[0].cost" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Selling Price" name="variants[0].price" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Minimum Qty" name="variants[0].stock.minQty" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Maximum Qty" name="variants[0].stock.maxQty" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Discount %" name="variants[0].discount" type="number" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <BooleanSelect label="Discount Status" name="variants[0].discountStatus" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Rack No" name="properties.rackNo" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Self No" name="properties.selfNo" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <BooleanSelect name="properties.narcotics" label="Narcotics" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <BooleanSelect name="properties.scheduleH" label="Schedule H" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper>
                <BooleanSelect name="properties.scheduleH1" label="Schedule H 1" layout="horizontal" />
              </FieldWrapper>
              <FieldWrapper></FieldWrapper>
            </UpperContainer> */}
            <UpperContainer>
              <FieldWrapper col={100}>
                <Input name="name" label="Name" />
              </FieldWrapper>
              <FieldWrapper col={100}>
                {/* <Input name="desc" label="Description" /> */}
                <TextArea name="desc" label="Description" rows={6} />
              </FieldWrapper>
              <FieldWrapper>
                <Input name="variants[0].sku" label="Item Code" />
              </FieldWrapper>
              <FieldWrapper>
                <Select label="Category" name="category" options={categoriesOptions} />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="M.R.P." placeholder="M.R.P." name={`variants[0].mrp`} type="number" />
              </FieldWrapper>
              <FieldWrapper>
                <Input label="Selling Price" placeholder="Selling Price" name={`variants[0].price`} type="number" />
              </FieldWrapper>


              {/* <FieldWrapper>
                <Select
                  name="tax"
                  label="Tax Category"
                  layout="horizontal"
                  options={[{ label: 'GST (12%)', value: '12' }, { label: 'GST (5%)', value: '5' }]}
                />
              </FieldWrapper> */}
              <FieldWrapper>
                <FileUpload label="Images" name="images" purpose="product" text="Upload Image" multiple imageStyle={{ height: '100px' }} />
              </FieldWrapper>
            </UpperContainer>
            {/* <GroupContainer label="Conversion">
              <UpperContainer>
                <FieldWrapper>
                  <Input label="Unit (2st)" name={`properties.units[0].unit`} layout="horizontal" />
                </FieldWrapper>
                <FieldWrapper>
                  <Input label="Conversion" name={`properties.units[0].conversion`} layout="horizontal" />
                </FieldWrapper>
              </UpperContainer>
            </GroupContainer> */}
            {/*
            <h3>Variants</h3>
            <VariantContainer>
              <FieldArray name="variants" render={arrayHelpers => (
                <div>
                  <Row className="header">
                    <FieldWrapper><Label>SKU</Label></FieldWrapper>
                    <FieldWrapper><Label>Variant Name</Label></FieldWrapper>
                    <FieldWrapper><Label>M.R.P.</Label></FieldWrapper>
                    <FieldWrapper><Label>Selling Price</Label></FieldWrapper>
                    <Button>X</Button>
                  </Row>
                  {props.values.variants.map((variant, index) => (
                    <div key={index}>
                      <Row>
                        <FieldWrapper>
                          <Input placeholder="SKU" name={`variants[${index}].sku`} />
                        </FieldWrapper>
                        <FieldWrapper>
                          <Input placeholder="Variant Name" name={`variants[${index}].variantName`} />
                        </FieldWrapper>
                        <FieldWrapper>
                          <Input placeholder="M.R.P." name={`variants[${index}].mrp`} type="number" />
                        </FieldWrapper>
                        <FieldWrapper>
                          <Input placeholder="Selling Price" name={`variants[${index}].price`} type="number" />
                        </FieldWrapper>
                        <Button variant="secondary" tabIndex={-1} onClick={() => arrayHelpers.remove(index)} disabled={props.values.variants.length === 1}>X</Button>
                      </Row>
                      // group container for pricelist comes here
                    </div>
                  ))}
                  <Button onClick={() => arrayHelpers.insert(props.values.variants.length, { ...emptyVariant })}>Add Variant</Button>
                </div>
              )} />
            </VariantContainer>
            */}
            {/* 
            {groups && groups.length > 0 && (
              <GroupContainer tw="mt-4" label="Other Prices">
                <div tw="flex flex-wrap w-full">
                  {groups.map(g => (
                    <div key={g.id} tw="w-1/3 px-1">
                      <Input placeholder={g.name} label={g.name} name={`variants[0].priceList[${g.id}].price`} type="number" />
                    </div>
                  ))}
                </div>
              </GroupContainer>
            )}
             */}
            {conditionalShippingPlans && conditionalShippingPlans.length > 0 && (
              <GroupContainer tw="mt-4" label="Selective Shipping Plans">
                <div tw="flex flex-wrap w-full">
                  {conditionalShippingPlans.map(lz => (
                    <div key={lz.id} tw="w-1/3 px-1">
                      <Select label="Shipping Plan" name="shippingPlan" options={state.options.conditionalShippingPlans} />
                    </div>
                  ))}
                </div>
              </GroupContainer>
            )}
            {landingZones && landingZones.length > 0 && (
              <GroupContainer tw="mt-4" label="Other Prices">
                <div tw="flex flex-wrap w-full">
                  {landingZones.map(lz => (
                    <div key={lz.id} tw="w-1/3 px-1">
                      <Input placeholder={lz.name + ' Price'} label={lz.name} name={`variants[0].priceList[${lz.id}].price`} type="number" />
                    </div>
                  ))}
                </div>
              </GroupContainer>
            )}
            <div style={{ marginTop: '16px' }}>
              <Button type="submit" loading={props.isSubmitting}>
                {id ? 'Update' : 'Create'}
              </Button>
              <Button variant="secondary" onClick={history.goBack}>Cancel</Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}