import React, { useState } from 'react';
import Input from '../../components/Input';
import styled from 'styled-components';
import Select from '../../components/Select';
import { toOptions } from '../../helpers/Utils';
import DataStore from '../../helpers/DataStore';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

export interface ProductsFilterParams {
  search: string;
  category: string;
}

interface Props {
  params?: ProductsFilterParams;
  onChange?: (value: ProductsFilterParams) => void;
}

export default function ProductsFilterPane(props: Props) {
  const { params, onChange } = props;
  let [options] = useState([
    { label: 'All', value: '' },
    ...toOptions(DataStore.getCategories())
  ])

  function handleChangeEvent(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    handleChange(event.target.value, event.target.name);
  }

  function handleChange(value: string | number | undefined, name: string) {
    let p = params || {} as ProductsFilterParams;
    value = value ? value.toString() : '';
    onChange && onChange({ ...p, [name]: value + '' })
  }

  return (
    <Container>
      <Input
        name="search"
        placeholder="Search (Name or Code)"
        style={{ flex: '0 1 500px' }}
        value={params?.search}
        onChange={handleChangeEvent}
      />
      <div style={{ width: '200px' }}>
        <Select
          label="Category"
          name="category"
          options={options}
          value={params?.category}
          onChange={handleChangeEvent}
        />
      </div>
    </Container>
  )
}