import React from 'react';
import Table from '../../../components/Table/TableLayout'


interface Props {
  data: Array<string>;
}

export default function ChargeItemView(props: Props) {
  const { data } = props;
  return (
    <Table.Row style={{ border: 'none' }}>
      <Table.Cell colSpan={4} style={{ textAlign: 'right' }}>{data[0]}</Table.Cell>
      <Table.Cell style={{ textAlign: 'right' }}>{data[1]}</Table.Cell>
    </Table.Row>
  )
}
