import React from "react";
import { DeleteIcon, EditIcon } from "../Icons";
import Table from "./TableLayout";
import getValue from "lodash/get";

/* Row Item */

interface RowItemProps {
  data: Array<CellValue>;
  index?: number;
  hover?: boolean;
  active?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  actions?: Array<Action>;
  onEdit?: () => void;
  onDelete?: () => void;
}

export function RowItem(props: RowItemProps) {
  const { data: row, index, hover, active, onClick, actions } = props;

  let actionsViews = null;
  if (actions) {
    actionsViews = actions.map(it => {
      switch (it) {
        case 'delete':
          return <Table.Cell icon key={it}><DeleteIcon onClick={props.onDelete} /></Table.Cell>
        case 'edit':
          return <Table.Cell icon key={it}><EditIcon onClick={props.onEdit} /></Table.Cell>
        default:
          if (index !== undefined)
            return <Table.Cell icon key={it.name}><span onClick={() => it.onAction(index, row)}>{it.icon}</span></Table.Cell>
      }
      return null;
    })
  }

  return (
    <Table.Row hover={hover} active={active} onClick={onClick}>
      {row.map((cell, i) => <Table.Cell key={i}>{cell}</Table.Cell>)}
      {actionsViews}
    </Table.Row>
  );
}

/* Data Table */

type CellValue = string | number | boolean | undefined;
type ColumnHead = string | { display: string, slug: string, align?: string };
type RowData = Array<CellValue> | Record<string, CellValue>;

type ActionHandler = (index: number, data: RowData) => void;

type Action = 'edit' | 'delete' | {
  name: string,
  icon: React.ReactNode,
  onAction: ActionHandler;
};

export interface Props {
  columns: Array<ColumnHead>;
  data?: Array<RowData>;
  valueMaps?: Record<string, Record<string, any>>;
  onItemClick?: ActionHandler;
  actions?: Array<Action>;
  onEdit?: ActionHandler;
  onDelete?: ActionHandler;
  hover?: boolean;
  selected?: number;
  placeholder?: string;
  noShadow?: boolean;
  style?: React.CSSProperties;
}

export function DataTable(props: React.PropsWithChildren<Props>) {
  let { selected, hover, columns, actions = [] } = props;

  let colSlugs: string[] = [];
  try {
    colSlugs = props.columns.map(c => {
      if (typeof c === 'object' && 'slug' in c) return c.slug;
      throw new Error('');
    }) as string[];
  } catch (e) { }

  function getCellValue(row: Record<string, CellValue>, slug: string) {
    let value = getValue(row, slug);
    if (value && props.valueMaps && props.valueMaps[slug]) {
      return props.valueMaps[slug][value.toString()]
    }
    return value
  }

  function renderItemView(data: Props['data']) {
    if (!data || data.length === 0) {
      let { placeholder } = props;
      let colSpan = columns.length + actions.length
      return <Table.Row><Table.PCell colSpan={colSpan}>{placeholder}</Table.PCell></Table.Row>;
    }

    return data?.map((row, i) => {
      const handleClick = () => props.onItemClick && props.onItemClick(i, row);
      const handleEdit = () => props.onEdit && props.onEdit(i, row);
      const handleDelete = () => props.onDelete && props.onDelete(i, row);

      let items: Array<CellValue> = []
      if (Array.isArray(row)) {
        // dataItem: [1, 'Ram', 28, 'Singapore']
        items = row;
      } else if (colSlugs.length) {
        // dataItem: { no: 1, name: 'Ram', age: 28, location: 'Singapore' }
        items = colSlugs.map(s => getCellValue(row, s)) // .map(s => row[s])
      }
      // } else {
      //   items = Object.values(row);
      // }

      return (
        <RowItem
          hover={hover}
          key={i}
          index={i}
          data={items}
          active={selected === i}
          onClick={handleClick}
          actions={actions}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )
    });
  }

  return (
    <Table.TableRoot style={props.style} noShadow={props.noShadow}>
      <Table.Head>
        <Table.Row>
          {columns.map((c, i) => <Table.HCell key={i}>{typeof c === 'string' ? c : c.display}</Table.HCell>)}
          {actions.length ? <Table.HCell colSpan={actions.length}></Table.HCell> : null}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {props.children || renderItemView(props.data)}
      </Table.Body>
    </Table.TableRoot>
  )
}
