import styled from 'styled-components';

const Header = styled.header`
  height: 56px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 2px #ddd;
`;

const Left = styled.div`
  border-right: #ddd;
  box-shadow: 2px 0 3px #ddd;

  overflow: auto;
`;

const Main = styled.main`
  overflow: auto;
`;

const Right = styled.div``;

const Footer = styled.footer``;

const Container = styled.div`
  height: 100%;
  display: grid;
  grid-template: auto 1fr auto / auto 1fr auto;

  ${Header} {
    grid-column: 1 / 4;
  }

  ${Left} {
    grid-column: 1 / 2;
  }

  ${Main} {
    grid-column: 2 / 3;

    // background: #f2f3f3;
    padding: 32px;
  }

  ${Right} {
    grid-column: 3 / 4;
  }

  ${Footer} {
    grid-column: 1 / 4;
  }
`;

export default {
  Container,
  Header,
  Left,
  Main,
  Right,
  Footer
}