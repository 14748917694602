import React from "react";
import styled from "styled-components";

import { Label, Error } from "../Typo";

type HtmlProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export interface Props extends HtmlProps {
  label?: string;
  error?: string | boolean;
  layout?: 'horizontal' | 'vertical'
}

interface WrapperProps {
  error?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  &.horizontal {
    flex-direction: row;
  }

  &.horizontal > ${Label} {
    padding-top: 6px;
    flex: 1;
  }
  &.horizontal > ${Label} + div {
    flex: 2;
  }

  ${p => `  
    input {
      height: 38px;
      border-radius: 4px;
      border: 1px solid #cccccc;
      font-size: 16px;
      padding: 2px 10px;
      box-sizing: border-box;
      transition: all 100ms;
      width: 100%;
      ${p.error && "border-color: red;"}

      &:hover {
        border-color: #b3b3b3;
        ${p.error && "border-color: red;"}
      }

      &:focus {
        border-color: ${p.theme.primary};
        box-shadow: 0 0 0 1px ${p.theme.primary};
        outline: 0 !important;
        ${p.error && `
          border-color: red;
          box-shadow: 0 0 0 1px red;
        `}
      }

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &[type=file] {
        padding: 6px;
      }
    }
  `}
`;
Wrapper.displayName = "InputWrapper";

// const format = "mobile";

function Input(props: Props) {
  let { className = '', style, label, error, layout, ...fieldProps } = props;
  // let [state, setState] = useState({})

  // if (format === "mobile") {
  //   // check = a => { [, c, m] = a.match(/^(\+\d+)? ?(.*)/); return {c, m} }
  //   let a = props.value;
  //   if (typeof a === "string") {
  //     let mobile = a.substr(a.indexOf(' ') + 1)
  //     let code = a.substr(0, a.indexOf(' '))
  //   }
  // }

  let layoutView = (
    <>
      <input {...fieldProps} />
      {error && <Error>{error}</Error>}
    </>
  );

  if (layout === 'horizontal') className += ' horizontal';

  return (
    <Wrapper style={style} className={className} error={!!error}>
      {label && <Label>{label}</Label>}
      {layout === 'horizontal' ? <div>{layoutView}</div> : layoutView}
    </Wrapper>
  );
}

Input.defaultProps = {
  value: ''
}


// export default withTheme(Input);
export default Input;
