import React, { PropsWithChildren } from "react";
import ModalStyled, { Props as ModalProps } from ".";
import { useModalAction, useModalState } from "./ModalProvider";

export interface Props extends Omit<ModalProps, 'isOpen'> {
  id: string;
}

function SimpleModal(props: PropsWithChildren<Props>) {
  let id = props.id;
  let isOpen = useModalState(id);
  let { setModal } = useModalAction(id);

  // let ref = React.useRef(0);
  // console.log('SimpleModal', id, ++ref.current)

  // if (!isOpen) return <></>;

  const handleClose = React.useCallback((event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    setModal(false);
    props.onRequestClose && props.onRequestClose(event)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.onRequestClose]);

  return (
    <ModalStyled
      {...props}
      isOpen={isOpen}
      onRequestClose={handleClose}
    >
      {props.children}
    </ModalStyled>
  )
}

export default SimpleModal;
