import moment from 'moment';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { atom, useRecoilState } from 'recoil';
import { GroupIcon } from '../../components/Icons';
import { useModalAction } from '../../components/Modal/ModalProvider';
import SimpleModal from '../../components/Modal/SimpleModal';
import PageTitleContainer from '../../components/PageTitleContainer';
import { DataTable } from '../../components/Table/DataTable';
import { PageTitle } from '../../components/Typo';
import { AppContext } from '../../context/AppContext';
import { fetcher } from '../../helpers/Api';
import { Customer } from '../../helpers/Responses';
import CustomerDetail from './CustomerDetail';
import CustomersFilterPane, { CustomersFilterParams } from './CustomersFilterPane';


const NO_GROUP = '(Without Group)';

export const customersListParamsAtom = atom<CustomersFilterParams>({
  key: 'customersListParams',
  default: { group: '', search: '' }
});

function useCustomerDetail() {
  let { setModal } = useModalAction('customer-detail')
  let [state, setState] = useState<Customer | null>();

  function setCustomerModal(isOpen: boolean, customer?: Customer) {
    setState(isOpen ? customer : null)
    setModal(isOpen)
  }

  return [state, setCustomerModal] as const;
}

export default function Customers() {
  let [params, setParams] = useRecoilState(customersListParamsAtom);
  let { state } = useContext(AppContext);
  let [modalData, setModalData] = useCustomerDetail();

  const { data: customers, isLoading } = useQuery<Customer[]>("listCustomers", fetcher)
  const groupsMap = state.map.customerGroups;
  const groupsOptions = state.options.customerGroups;

  const paramsSearch = (params?.search || '').trim().toLowerCase();

  let fData = customers;
  if (paramsSearch || params.group !== '') {
    fData = customers?.filter((c, i) => {
      let { name = '', email = '', mobile = '', groups = [] } = c;
      if (
        !name.toLowerCase().includes(params.search)
        && !email.toLowerCase().includes(params.search)
        && !mobile.toLowerCase().includes(params.search)
      ) return false;

      if (params.group === '') {
        return true
      } else if (params.group === NO_GROUP) {
        if (groups.length === 0) return true;
      } else {
        if (groups.find(g => g.id === params.group)) return true
      }
    })
  }

  const data = fData?.map((c, i) => {
    let date = moment(c.createdAt).format('DD MMM YYYY');
    let group = c.groups?.map(g => groupsMap[g.id]).filter(it => !!it).join(', ') || ''
    return ([i + 1, c.name, c.mobile, c.email, group, date])
  })

  const options = [
    { label: 'All', value: '' },
    { label: NO_GROUP, value: NO_GROUP },
    ...groupsOptions
  ]

  const actions = [
    {
      name: 'group',
      icon: <GroupIcon />,
      onAction: (index: number) => {
        if (!Array.isArray(fData)) return;
        let data = fData[index];
        setModalData(true, data)
      }
    }
  ]

  return (
    <>
      <PageTitleContainer>
        <PageTitle>Customers</PageTitle>
      </PageTitleContainer>
      <CustomersFilterPane params={params} onChange={setParams} options={options} />
      <DataTable
        columns={['S.No', 'Name', 'Mobile', 'Email', 'Group', 'Registered On']}
        placeholder={isLoading ? "Loading .." : "No Customers"}
        data={data}
        actions={actions}
      />
      <SimpleModal id="customer-detail" maxWidth="400px">
        <CustomerDetail customer={modalData} onSave={() => setModalData(false)} />
      </SimpleModal>
    </>
  )
}