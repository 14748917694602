import styled from "styled-components";

interface TableRootProps {
  noShadow?: boolean;
}

const TableRoot = styled.table<TableRootProps>`
  width: 100%;
  border-collapse: collapse;
  border-radius: 3px;
  overflow: hidden;
  background: white;

  @media screen {
    ${p => !p.noShadow && 'box-shadow: 1px 1px 1px 1px #bbb;'}
  }
`;

const Head = styled.thead`
  border-top: 1px solid #ddd;
  background: #ddd;  
`;

const Body = styled.tbody``;

interface RowProps {
  hover?: boolean;
  active?: boolean;
}

const Row = styled.tr<RowProps>`
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;

  ${p => p.hover && `
    cursor: pointer;
    &:hover {
      background: #fafafa;
    }
  `}

  ${p => p.active && `
    background: ${p.theme.selection};
    border-color: ${p.theme.selection};

    &:hover {
      background: ${p.theme.selection};
      border-bottom-color: ${p.theme.selection};
    }
  `}
`;

interface CellProps {
  icon?: boolean;
}

/**
 * TBody Cell <td></td>
 */
const Cell = styled.td<CellProps>`
  padding: 12px;
  font-size: 14px;
  color: #16191f;
  ${p => p.icon && 'width: 24px;'}

  @media print {
    padding: 4px 8px;
    font-size: 12px;
  }
`;

/**
 * THead Cell <th></th>
 */
const HCell = styled.th`
  text-align: left;
  padding: 12px;
  position: relative;
  color: #545b64;
  font-size: 14px;

  :not(:first-child):before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 25%;
    height: 50%;
    border-left: 1px solid #bbb;
    box-sizing: border-box;
  }

  @media print {
    padding: 4px 8px;
    font-size: 12px;
  }
`;

/**
 * Placeholder Cell <td></td>
 */
const PCell = styled(Cell)`
  color: #687078;
  text-align: center;
`;

export default {
  TableRoot, Head, Body, Row, Cell, HCell, PCell
}