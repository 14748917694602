import { Delete, Edit, Group } from "@styled-icons/material";
import styled, { css } from "styled-components";

const ctaIcon = css`
  display: block;
  height: 24px;
  color: #CCC;
  cursor: pointer;
`;

export const EditIcon = styled(Edit)`
  ${ctaIcon}
  &:hover {
    color: ${p => p.theme.primary};
  }
`;

export const DeleteIcon = styled(Delete)`
  ${ctaIcon}
  &:hover {
    color: ${p => p.theme.danger};
  }
`;

export const GroupIcon = styled(Group)`
  ${ctaIcon}
  &:hover {
    color: ${p => p.theme.primary};
  }
`;
