import React from 'react';
import { useField } from 'formik';
import Select, { Props } from '../Select';

export default function FormikSelect(props: Props) {
  const [field, meta] = useField({ name: props.name || '' });
  return <Select {...field} {...props} error={meta.touched && meta.error} />
}


/* Component: Boolean Select */

const optionsYesNo = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' }
];

const optionsYesNoBlank = [
  { label: ' - ', value: '-' },
  ...optionsYesNo,
];

export interface BooleanSelectProps extends Omit<Props, 'value' | 'options'> {
  value?: Props['value'] | boolean;
  allowBlank?: boolean;
};

export function FormikBooleanSelect(props: BooleanSelectProps) {
  const [field, meta, helper] = useField({ name: props.name || '' });

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>) {
    let booleanValue = e.target.value === 'Y';
    helper.setValue(booleanValue);
    console.log(props.name, 'handleChange booleanValue', booleanValue, e.target.value)
  }

  let stringValue = field.value === true ? 'Y' : 'N';
  // console.log(props.name, 'renderValue stringValue', stringValue, props.value)

  return <Select {...field} {...props} error={meta.touched && meta.error} options={optionsYesNo} value={stringValue} onChange={handleChange} />
}

export const BooleanSelect = FormikBooleanSelect;