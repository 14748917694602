import React, { useState } from "react";

interface ActionProps {
  setModalIdFunFun: (id: string) => (isOpen: boolean) => void;
  setModalById: (id: string, isOpen: boolean) => void;
}

const ModalStateContext = React.createContext<Record<string, boolean>>({});
const ModalActionContext = React.createContext<ActionProps | undefined>(undefined);

export function ModalProvider(props: React.PropsWithChildren<{}>) {
  let [state, setState] = useState<Record<string, boolean>>({})

  const actions = React.useMemo(() => ({
    setModalIdFunFun: (id: string) => (isOpen: boolean) => setState(s => ({ ...s, [id]: isOpen })),
    setModalById: (id: string, isOpen: boolean) => setState(s => ({ ...s, [id]: isOpen })),
  }), [])

  return (
    <ModalStateContext.Provider value={state}>
      <ModalActionContext.Provider value={actions}>
        {props.children}
      </ModalActionContext.Provider>
    </ModalStateContext.Provider>
  )
}

export function useModalState(id: string) {
  const context = React.useContext(ModalStateContext)
  if (context === undefined) {
    throw new Error('useModalState must be used within a ModalProvider')
  }
  return context[id]
}

export function useModalAction(id: string): { setModal(isOpen: boolean): void }

export function useModalAction(): { setModal(id: string, isOpen: boolean): void }

export function useModalAction(id?: any) {
  const context = React.useContext(ModalActionContext)
  if (context === undefined) {
    throw new Error('useModalAction must be used within a ModalProvider')
  }
  const { setModalIdFunFun, setModalById } = context;
  return { setModal: id ? setModalIdFunFun(id) : setModalById };
}
