import { AxiosResponse } from "axios";
import { Formik, FormikHelpers } from "formik";
import React from "react";
import { DynamicForm, UiSchema } from "../../components/DynamicForm";
import { useModalAction } from "../../components/Modal/ModalProvider";
import SimpleModal from "../../components/Modal/SimpleModal";


export type Props<T = any> = {
  modalId: string;
  title: string;
  uiSchema?: UiSchema;
  createApi?: (data: any) => Promise<AxiosResponse>;
  updateApi?: (id: string, data: any) => Promise<AxiosResponse>;
  onSubmit: (data: any, isNew: boolean) => {};
  onClose?: () => void;
  initialData?: T;
  width?: string;
}

const simpleUiSchema: UiSchema = [
  { widget: 'input', name: 'name', autoFocus: true }
]

export default function CommonModal(props: React.PropsWithChildren<Props>) {
  const { setModal } = useModalAction(props.modalId);
  const isEditMode = !!props.initialData?.id;

  async function handleSubmit(values: any, helpers: FormikHelpers<{}>) {
    if (!isEditMode) {
      if (props.createApi) {
        const res = await props.createApi(values);
        values = res.data;
      }
    } else {
      if (props.updateApi) {
        await props.updateApi(values.id, values);
        values = { id: props.initialData?.id, ...values };
      }
    }
    props.onSubmit && props.onSubmit(values, !isEditMode);
    helpers.resetForm();
    setModal(false);
  }

  const uiSchema: UiSchema = [
    ...(props.uiSchema || simpleUiSchema),
    { widget: 'button', type: 'submit', text: isEditMode ? 'Update' : 'Create', align: 'right', style: { marginTop: '16px' } }
  ];

  //  buttons={['Cancel', { text: 'Create', onClick: handleCreate }]}
  return (
    <SimpleModal id={props.modalId} title={`Create New ${props.title}`} onRequestClose={props.onClose} width={props.width}>
      <Formik initialValues={props.initialData || {}} onSubmit={handleSubmit}>
        {props.children || <DynamicForm uiSchema={uiSchema} />}
      </Formik>
    </SimpleModal>
  )
}

CommonModal.defaultProps = {
  width: '550px'
}
