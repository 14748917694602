import DataStore from "../helpers/DataStore"

type TenantConfig = {
  name: string;
  theme?: Record<string, string>;
}

const kyrios: TenantConfig = {
  name: 'Kyrios',
}

const market4home: TenantConfig = {
  name: 'Market4Home',
  theme: {
    primary: "#278040", // '#65b32d'
  }
}

const navanee: TenantConfig = {
  name: 'Navanee'
}

const main: TenantConfig = {
  name: 'Main Tenant'
}

const tenantConfigs: Record<string, TenantConfig> = {
  kyrios,
  market4home,
  navanee,
  main
}

const tenantConfig = tenantConfigs[DataStore.getTenant()];

export default tenantConfig;
