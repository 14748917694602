import React from "react";
import styled from "styled-components";
import { Formik, Form, FormikProps } from "formik";
import Ajv from 'ajv';
import Input from "../../components/Input";
import Button from "../../components/Button";
import { FieldWrapper } from "../../components/Wrapper";
import api from "../../helpers/Api";
import { MultiError, validation } from '../../helpers/Validation';
import { getFieldProps } from '../../helpers/Utils';
import { Redirect, useHistory } from 'react-router-dom';
import DataStore from "../../helpers/DataStore";
import { Routes } from "../../helpers/Constants";
import tenantConfig from "../../data/tenantConfig";
import "twin.macro";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: #EEE;

  footer {
    font-size: 12px;
    color: #AAA;
    margin-top: 8px;
  }
`;

const FootNote = styled.div`
  font-size: 10px;
  color: grey;
  text-align: center;
  padding: 8px;

  span {
    display: block;
  }
`;

const Box = styled.div`
  width: 300px;
  margin-top: 24px;
  padding: 24px;
  border-radius: 5px;
  background: white;

  h2 {
    color: #333;
    margin-top: 0;
    text-align: center;
    padding-bottom: 16px;
    border-bottom: 2px solid #EEE;
  }
`;

const ButtonWrapper = styled(FieldWrapper)`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;  
`;

interface LoginValues {
  email: string;
  password: string;
};

const initialValues: LoginValues = { email: "", password: "" };

// const initialValues: LoginValues = process.env.NODE_ENV === "development"
//   ? { email: "merchant@domain.com", password: "password" }
//   : { email: "", password: "" };

const ajv = new Ajv({ allErrors: true });
const validateLogin = ajv.compile({
  properties: {
    email: {
      type: 'string',
      pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$'
    },
    password: {
      type: 'string',
      minLength: 8
    }
  },
  required: ["email", "password"]
});

const errorMessages: MultiError = {
  email: {
    required: "Email is required",
    default: "Invalid email"
  },
  password: {
    required: "Password is required",
    minLength: "Password must be atleast 8 characters long"
  }
}

const brandImg = `${process.env.REACT_APP_BASE_IMG}/${DataStore.getTenant()}/branding`;

function Login() {
  let history = useHistory();

  if (DataStore.getToken()) {
    return <Redirect to={{ pathname: Routes.Dashboard }} />
  }

  async function handleSubmit(values: LoginValues) {
    let { email, password } = values;
    // TODO: handle error
    let res = await api.login(email, password);
    DataStore.setTenant(DataStore.getTenant());
    DataStore.setToken(res.data.token);
    history.push(Routes.Dashboard);
  }

  function validate(values: LoginValues) {
    return validation(validateLogin, values, errorMessages);
  }

  return (
    <Container>
      {/* <img src={brandImg + '/logo.png'} alt="Logo" style={{ maxWidth: '160px' }} /> */}
      <h1 tw="text-2xl font-bold">{tenantConfig.name}</h1>
      <Box>
        <h2 tw="text-xl font-bold">Merchant Login</h2>
        <Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
          {(props: FormikProps<LoginValues>) => (
            <Form>
              <FieldWrapper>
                <Input
                  label="Email"
                  type='email'
                  placeholder="user@domain.com"
                  autoFocus
                  {...getFieldProps(props, "email")}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Input
                  label="Password"
                  type="password"
                  placeholder="password"
                  {...getFieldProps(props, "password")}
                />
              </FieldWrapper>
              <ButtonWrapper>
                <Button type="submit" loading={props.isSubmitting}>Login</Button>
                <Button variant="link" style={{ marginLeft: 0, paddingLeft: 0, paddingRight: 0 }}>
                  Forgot Password?
                </Button>
              </ButtonWrapper>
            </Form>
          )}
        </Formik>
      </Box>
      <FootNote>
        <span>AgamVanigam {process.env.REACT_APP_VERSION}</span>
        <span>Powered by AgamWorks</span>
      </FootNote>
    </Container>
  );
}

export default Login;
