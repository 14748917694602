import React from 'react';
import ReactToPrint from 'react-to-print';
import styled from 'styled-components';
import { Address } from '../helpers/Responses';
import { StyledButton } from './Button';

/* Address View */

interface AddressViewProps {
  data?: Address;
  style?: React.CSSProperties;
}

export function AddressView(props: AddressViewProps) {
  const { data, style } = props;
  if (!data) return <></>;

  let line2 = data.city || '';
  if (line2 && data.pincode) line2 += ' - ' + data.pincode;

  let line3 = data.state || '';
  if (line3 && data.country) line3 += ', ' + data.country;


  return (
    <div style={style}>
      <div>{data.street}</div>
      <div>{data.area}</div>
      <div>{line2}</div>
      <div>{line3}</div>
      {data.landmark && <div>Landmark: {data.landmark}</div>}
    </div>
  )
}

/* Print Button */

const PrintButton = styled(StyledButton)`
  @media print {
    & {
      display: none;
    }
  }
`;

interface PrintProps {
  content: ReactToPrint["context"]
}

export function Print(props: PrintProps) {
  return <ReactToPrint
    content={props.content}
    trigger={() => <PrintButton variant="secondary" size="small">Print</PrintButton>}
  />
}
