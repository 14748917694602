import React, { useCallback, useEffect, useRef, useState } from 'react';
import Api from '../../helpers/Api';
import { Product } from '../../helpers/Responses';
import { DataTable } from "../../components/Table/DataTable";
import Button from '../../components/Button';
import { PageTitle } from '../../components/Typo';
import { useHistory } from 'react-router-dom';
// import { atom, useRecoilState, selector, useRecoilValue, useRecoilCallback, selectorFamily, useSetRecoilState } from 'recoil'
import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import PageTitleContainer from '../../components/PageTitleContainer';
import DataStore from '../../helpers/DataStore';
import { currency, download, toMap } from '../../helpers/Utils';
import ProductsFilterPane, { ProductsFilterParams } from './ProductsFilterPane';
import { toast } from 'react-toastify';
import { Routes } from '../../helpers/Constants';

export const productsListAtom = atom<Product[]>({
  key: 'productsList',
  default: []
});

export const productsListParamsAtom = atom<ProductsFilterParams>({
  key: 'productsListParams',
  default: { category: '', search: '' }
});

const filteredProductsListSelector = selector({
  key: 'filteredProductsList',
  get: ({ get }) => {
    const params = get(productsListParamsAtom);
    const searchText = params.search?.toLowerCase()
    const products = get(productsListAtom);
    const filtered = products.filter(p => {
      // let { name = '', code = '' } = p;
      let { name = '' } = p;
      if (name.toLowerCase().includes(searchText)) return true;
      // if (code.toLowerCase().includes(params.search)) return true;
      return false;
    });
    return filtered;
  }
})

// const productsListRefreshAtom = atom({
//   key: 'productsListRefresh',
//   default: 0
// });


// const getProductsListSelector = selector({
//   key: 'getProductsList',
//   get: async ({ get }) => {
//     console.log('getProductsList selector');
//     // get(productsListRefreshAtom)
//     let res = await Api.listProducts(get(productsListParamsAtom));
//     return res.data;
//   }
// });

export default function ProductsList() {
  const categoriesMap = toMap(DataStore.getCategories());
  let history = useHistory();
  let [loading, setLoading] = useState(false);
  // let [params, setParams] = useState<ProductsFilterParams>();

  /* recoil state */
  let [params, setParams] = useRecoilState(productsListParamsAtom);
  let fProducts = useRecoilValue(filteredProductsListSelector);
  let setProductsRef = useRef(useSetRecoilState(productsListAtom));
  // let [products, _setProducts] = useRecoilState<Product[]>(productsListAtom);
  // let setProductsRef = useRef(_setProducts);


  // let products = useRecoilValue(getProductsListSelector);

  // const refreshProducts = useRecoilCallback(({ snapshot }) => async () => {
  //   console.log('pp refresh callback');
  //   const results = await snapshot.getPromise(getProductsListSelector);
  //   console.log(results);
  // });

  // const setRefresh = useSetRecoilState(productsListRefreshAtom);
  // const refreshProducts = () => setRefresh(id => ++id);

  /* react state */
  // let [products, setProducts] = useState<Product[]>([]);
  let paramCategory = params?.category;

  const loadProducts = useCallback(() => {
    setLoading(true);
    const setProducts = setProductsRef.current;
    return Api.listProducts({ category: paramCategory })
      .then(res => setProducts(res.data))
      .finally(() => setLoading(false));
  }, [paramCategory])

  useEffect(() => { loadProducts() }, [loadProducts])

  const data = fProducts.map(p => ([
    // p.code,
    p.variants[0].sku,
    p.name,
    categoriesMap[p.category || ''],
    p.variants.map(v => currency(v.price)).join(' / ')
  ]));

  function handleItemClick(index: number) {
    // show first sku in list and click findby this first sku
    // let product = fProducts.find(p => p.code === data[index][0]);
    let product = fProducts.find(p => p.variants[0].sku === data[index][0]);
    if (product && product.id)
      history.push(Routes.ProductDetails(product.id), product)
  }

  async function handleItemDelete(index: number) {
    let product = fProducts.find(p => p.variants[0].sku === data[index][0]);
    let pid = product && product.id;
    if (pid) {
      await Api.deleteProduct(pid)
      const setProducts = setProductsRef.current;
      setProducts(products => {
        products = [...products]
        const i = products.findIndex(p => p.id === pid);
        products.splice(i, 1);
        return products;
      })
    }
  }

  function csvItem(it: any) {
    if (it === undefined || it === null) return '';
    if (typeof it === 'string') it = it.replace(/"/g, '""');
    return `"${it}"`
  }

  function handleExport() {
    if (!fProducts.length)
      return toast.info('You\'re trying to export an empty list');

    let yes = window.confirm("Export the (filtered) products list?");
    if (!yes) return;

    let array: Array<any> = [];
    fProducts.forEach(it => {
      let p = [it.id, it.name, it.desc];
      // let p = [it.id, it.code, it.name, it.desc];
      it.variants.forEach(v => array.push([...p, v.id, v.sku, v.variantName, v.mrp, v.price]));
    });

    // let csvText = "id,code,name,desc,variantId,sku,variantName,mrp,price\n"
    let csvText = "id,name,desc,variantId,sku,variantName,mrp,price\n"
    csvText += array.map(row => row.map(csvItem).join(',')).join('\n');

    let nameChunk = ['products-' + Date.now()]
    if (params.category) nameChunk.push(categoriesMap[params.category].replace(/\W+/g, '_'));
    if (params.search) nameChunk.push(params.search);
    download(csvText, nameChunk.join('-') + '.csv', 'text/csv');
    // let csvContent = "data:text/csv;charset=utf-8," + csvText;
    // var encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
  }

  const refImport = React.useRef<HTMLInputElement>(null);
  let [importLoading, setImportLoading] = useState(false);
  function handleImportClick() {
    // refImport.current && refImport.current.click();
  }

  async function handleImportFile(event: React.ChangeEvent<HTMLInputElement>) {
    if (!refImport.current) return;

    const file = event?.target?.files && event.target.files[0];
    if (!file) return;

    let yes = window.confirm("Import csv file and bulk update products?");
    if (!yes) { refImport.current.value = ''; return; }

    const formData = new FormData();
    formData.append('products', file);

    setImportLoading(true);
    await Api.bulkProducts(formData)
      .then(loadProducts)
      .finally(() => setImportLoading(false));

    toast.success('CSV Imported and Products Updated');
    refImport.current.value = '';
  }

  return (
    <>
      <PageTitleContainer>
        <PageTitle>Item Master</PageTitle>
        <div>
          <input type="file" ref={refImport} accept="text/csv" onChange={handleImportFile} style={{ display: 'none' }} />
          <Button variant="secondary" onClick={handleImportClick} loading={importLoading}>Import</Button>
          <Button variant="secondary" onClick={handleExport}>Export</Button>
          <Button onClick={() => history.push(Routes.ProductDetails('new'))}>Create New Product</Button>
        </div>
      </PageTitleContainer>
      <ProductsFilterPane params={params} onChange={setParams} />
      <DataTable
        columns={['Code', 'Name', 'Category', 'Price']}
        data={data}
        placeholder={loading ? "Loading .." : "No Products"}
        actions={['edit', 'delete']}
        onEdit={handleItemClick}
        onDelete={handleItemDelete}
      // hover
      // onItemClick={handleItemClick}
      />
    </>
  );
}