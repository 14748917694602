import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import api from '../../helpers/Api';
import { Order } from '../../helpers/Responses';
import { DataTable } from "../../components/Table/DataTable";
import { PageTitle } from '../../components/Typo';
import PageTitleContainer from '../../components/PageTitleContainer';
import OrderDetail from './OrderDetail';
import { removeMobileCode, currency } from '../../helpers/Utils';
import Button from '../../components/Button';
import OrdersFilterPane, { OrderFilterParams } from './OrdersFilterPane';
import { AppContext } from '../../context/AppContext';

export default function Orders() {

  let { state } = useContext(AppContext);

  let [orders, setOrders] = useState<Order[]>([]);
  let [loading, setLoading] = useState(true);
  let [selectedIndex, setSelectedIndex] = useState<number>();
  let [selected, setSelected] = useState<Order>();
  let [params, setParams] = useState<OrderFilterParams>();

  let status = params?.status
  let payStatus = params?.payStatus
  let delStatus = params?.delStatus
  useEffect(() => {
    // TODO: handle error
    setLoading(true);
    api.listOrders({ status, payStatus, delStatus })
      .then(res => setOrders(res.data))
      .finally(() => setLoading(false));
  }, [status, payStatus, delStatus])

  const columns = selected
    ? ['#', 'Name', 'Total', 'Order Status', 'Payment', 'Ordered At']
    : ['#', 'Name', 'Mobile', 'Items', 'Total', 'Order Status', 'Payment', 'Delivery', 'Ordered At'];
  const paramsSearch = (params?.search || '').trim().toLowerCase();
  const filtered = !paramsSearch ? orders : orders.filter(o => {
    let { name, mobile } = o.shippingDetails || {};
    if (name?.toLowerCase().includes(paramsSearch)) return true;
    if (mobile?.toLowerCase().includes(paramsSearch)) return true;
    return false;
  })
  const data = selected
    ? filtered.map((o, i) => ([
      o.no,
      o.shippingDetails?.name,
      currency(o.total),
      state.map.orderStatus[o.status],
      state.map.payStatus[o.payStatus],
      moment(o.createdAt).format('DD MMM, hh:mm A')
    ]))
    : filtered.map((o, i) => ([
      o.no,
      o.shippingDetails?.name,
      removeMobileCode(o.shippingDetails?.mobile),
      o.lineItems.length,
      currency(o.total),
      state.map.orderStatus[o.status],
      state.map.payStatus[o.payStatus],
      state.map.delStatus[o.delStatus],
      moment(o.createdAt).format('DD MMM, hh:mm A')
    ]));

  return <>
    <PageTitleContainer>
      <PageTitle>Orders</PageTitle>
      {selected && <Button variant="secondary" onClick={() => { setSelectedIndex(undefined); setSelected(undefined) }}>Clear Selection</Button>}
    </PageTitleContainer>
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <OrdersFilterPane params={params} onChange={setParams} />
        <DataTable
          columns={columns}
          data={data}
          hover
          selected={selectedIndex}
          placeholder={loading ? "Loading .." : "No Orders"}
          onItemClick={i => { !!orders && setSelected(orders[i]); setSelectedIndex(i) }}
        />
      </div>
      {selected &&
        <div style={{ flex: 1 }}>
          <OrderDetail order={selected} />
        </div>
      }
    </div>
  </>;
}