export const StorageKeys = {
  Token: 'token',
  LandingZone: 'landingZone',
  Tenant: 'tenant',
  Categories: 'categories',
}

export const Routes = {
  Login: '/login',
  Dashboard: '/dashboard',
  // Products: '/products',
  Orders: '/orders',
  Customers: '/customers',
  Settings: '/settings',

  // master
  Master: '/master',

  // master >> SupplierMaster
  LedgerMaster: '/master/ledgers',
  SupplierMaster: '/master/suppliers',

  // master >> InventoryMaster
  InventoryMaster: '/master/inventory',
  Products: '/master/inventory/products',
  ProductDetails: (id: string) => `/master/inventory/products/${id}`,
  TaxGroups: '/master/inventory/tax-groups',
  CompanyMaster: '/master/inventory/companies',
  Categories: '/master/inventory/categories',
  SaltMaster: '/master/inventory/salt',

  // master >> OtherMaster
  OtherMaster: '/master/others',
  AreaMaster: '/master/others/areas',
  RouteMaster: '/master/others/routes',
  SalesmenMaster: '/master/others/salesmen',

  // transactions
  Transactions: '/transactions',
  SaleOrders: '/transactions/sale-orders',
  PurchaseOrders: '/transactions/purchase-orders',
  SaleReturns: '/transactions/sale-returns',
  PurchaseReturns: '/transactions/purchase-returns',
}
