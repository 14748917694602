import React from "react";
import styled from "styled-components";

import { Label, Error } from "../Typo";

export interface Option {
  label: string;
  value: string;
}

export interface Props {
  name?: string;
  options: Option[];
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  className?: string;
  style?: React.CSSProperties;

  label?: string;
  error?: string | boolean;
  layout?: 'horizontal' | 'vertical';
}

interface WrapperProps {
  error?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  &.horizontal {
    flex-direction: row;
  }

  &.horizontal > ${Label} {
    padding-top: 6px;
    flex: 1;
  }
  &.horizontal > ${Label} + div {
    flex: 2;
  }

  select {
    height: 38px;
    border-radius: 4px;
    border: 1px solid #cccccc;
    font-size: 16px;
    padding: 2px 36px 2px 10px;
    box-sizing: border-box;
    transition: all 100ms;
    width: 100%;
    ${p => p.error && "border-color: red;"}

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
      linear-gradient(45deg, transparent 50%, #bbb 50%),
      linear-gradient(135deg, #bbb 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 18px) calc(1em + 0px),
      calc(100% - 12px) calc(1em + 0px),
      calc(100% - 36px) 0.5em;
    background-size:
      6px 6px,
      6px 6px,
      1px 1.3em;
    background-repeat: no-repeat;

    :after {
      content: " ";
      height: 20px;
      width: 20px;
      background: red;
    }

    &:hover {
      border-color: #b3b3b3;
      ${p => p.error && "border-color: red;"}
    }

    &:focus {
      background-image:
        linear-gradient(45deg, transparent 50%, #777 50%),
        linear-gradient(135deg, #777 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);

      border-color: ${p => p.theme.primary};
      box-shadow: 0 0 0 1px ${p => p.theme.primary};
      outline: 0 !important;
      ${p => p.error && `
        border-color: red;
        box-shadow: 0 0 0 1px red;      
      `}
    }
  }
`;
Wrapper.displayName = "SelectWrapper";

function Select(props: Props) {
  let { name, value, onChange, onBlur, options, className = '', style, label, error, layout } = props;

  let layoutView = (
    <>
      <select name={name} onChange={onChange} onBlur={onBlur} value={value}>
        {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
      </select>
      {error && <Error>{error}</Error>}
    </>
  );

  if (layout === 'horizontal') className += ' horizontal';

  return (
    <Wrapper style={style} className={className} error={!!error}>
      {label && <Label>{label}</Label>}
      {layout === 'horizontal' ? <div>{layoutView}</div> : layoutView}
    </Wrapper>
  );
}

export const StyledSelect = styled(Select)``;

export default Select;
