import { FieldArray, Form, Formik } from 'formik';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import FileUpload from '../../components/FormikField/FileUpload'
// import Input from '../../components/FormikField/Input';
import PageTitleContainer from '../../components/PageTitleContainer';
import { PageTitle } from '../../components/Typo';
import { AppActions, AppContext } from '../../context/AppContext';
import Api from '../../helpers/Api';
import { BannerItem } from '../../helpers/Responses';
import { generateChars } from '../../helpers/Utils';
import { MultiError, validation } from '../../helpers/Validation';
import Ajv from 'ajv';
import { bannerSchema } from '../../helpers/Schemas';

const ajv = new Ajv({ allErrors: true });
const validateBanners = ajv.compile({
  type: 'object',
  properties: {
    banners: bannerSchema
  }
});

const errorMessages: MultiError = {
  banners: [
    {
      image: 'Please upload a valid image'
    }
  ]
}

const RowItem = styled.div`
  display: flex;

  :hover {
    // background: #F7F7F7;
  }

  > :first-child {
    width: 360px;
    min-height: 120px;
  }
`;

type __id<T> = T & { __id: string; }

interface BannerForm {
  banners: __id<BannerItem>[]
}

export default function Settings() {

  const { state, dispatch } = useContext(AppContext);
  const banners = (state.configs.banners || []).map(it => ({ ...it, __id: generateChars() }));

  async function handleSubmit(values: BannerForm) {
    const payload = values.banners.map(it => ({ image: it.image }))
    const res = await Api.updateBanner(payload);
    dispatch(AppActions.UpdateBanners, res.data);
  }

  function handleValidation(values: BannerForm) {
    const errors = validation(validateBanners, values, errorMessages);
    console.log(errors);
    return errors;
  }

  return (
    <Formik initialValues={{ banners }} enableReinitialize onSubmit={handleSubmit} validate={handleValidation}>
      {(props) => (
        <>
          <Form>
            <PageTitleContainer style={{ justifyContent: 'flex-start' }}>
              <PageTitle>Settings &raquo; Banners</PageTitle>
              <Button type="submit" loading={props.isSubmitting}>Publish Banners</Button>
            </PageTitleContainer>
            <FieldArray name="banners" render={(helper) => (
              <>
                {props.values.banners.map((v, i) => (
                  <RowItem key={v.__id}>
                    <FileUpload
                      name={`banners[${i}].image`}
                      purpose="banner"
                      text="Upload"
                      imageStyle={{ maxWidth: '360px' }}
                    />
                    {/* <Input name={`banners[${i}].cta`} /> */}
                    <Button variant="secondary" onClick={() => helper.remove(i)}>Remove Banner</Button>
                  </RowItem>
                ))}
                <Button variant="secondary" onClick={() => helper.insert(props.values.banners.length, { __id: generateChars(), image: null })}>
                  Add More Banner
                </Button>
              </>
            )} />
            <Button type="submit" loading={props.isSubmitting}>Publish Banners</Button>
          </Form>
        </>
      )}
    </Formik>
  )
}