import React from "react";
import styled from 'styled-components';
import { matchPath, NavLink, useLocation } from 'react-router-dom';

// Styled Components

const FootNote = styled.div`
  font-size: 10px;
  color: grey;
  text-align: center;
  padding: 8px;

  span {
    display: block;
  }
`;

type ViewProps = { level: number; }

const SubMenuWrapper = styled.div<ViewProps>`
  padding: ${p => p.level === 1 ? '8px 0 8px 12px' : '0 0 0 12px'};
  ${p => p.theme.sidebar.subitem}
`;

const MenuItemView = styled(NavLink) <ViewProps>`
  padding: 24px 16px;
  display: block;
  text-decoration: none;
  ${p => p.theme.sidebar.item}

  &.nested {
    padding: 8px 12px;
    font-size: ${p => Math.max(10, 16 - p.level * 2)}px;
  }

  &:not(.nested) {
    border-top: 1px solid ${p => p.theme.sidebar.item.borderColor};
    &:last-child {
      border-bottom: 1px solid ${p => p.theme.sidebar.item.borderColor};
    }
  }

  &.active + &, &.active-parent + ${SubMenuWrapper} + & {
    border-top: none;
  }

  &.active-parent {
    ${p => p.theme.sidebar.item.activeParent}
    font-weight: bold;
  }

  &.active {      
    border-top: none;
    border-radius: 0 5px 5px 0;
    ${p => p.theme.sidebar.item.active}
    &.nested {
      border-radius: 5px 0 0 5px;
    }

    font-weight: 600;
  }
`;

const Container = styled.div`
  height: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  ${p => p.theme.sidebar}

  ${FootNote} {
    margin-top: auto;
  }
`;

// Component

export interface MenuItem {
  name: string;
  link: string;
  onClick?: (event: React.MouseEvent) => void;
  subMenus?: MenuItem[];
}

export interface Props {
  menus: MenuItem[];
  footNote?: string[] | string;
}

export default function Sidebar(props: Props) {

  const location = useLocation()

  function renderMenus(menus: MenuItem[], level = 0): React.ReactElement[] {
    const nextLevel = level + 1;
    const cls = level > 0 ? 'nested' : '';
    return menus.map((m, i) => {
      const match = matchPath(location.pathname, { path: m.link })
      const className = match ? cls + ' active-parent' : cls;
      return (
        <React.Fragment key={m.link}>
          <MenuItemView to={m.link} onClick={m.onClick} exact className={className} level={level}>
            {m.name}
          </MenuItemView>
          {m.subMenus && match && (
            <SubMenuWrapper level={nextLevel}>
              {renderMenus(m.subMenus, nextLevel)}
            </SubMenuWrapper>
          )}
        </React.Fragment>
      )
    })
  }

  let footNote: string[] | undefined;
  if (props.footNote)
    footNote = Array.isArray(props.footNote) ? props.footNote : [props.footNote]

  return (
    <Container>
      <div style={{ overflowY: 'auto' }}>{renderMenus(props.menus)}</div>
      {footNote && <FootNote>{footNote.map(it => <span key={it}>{it}</span>)}</FootNote>}
    </Container>
  );
}

Sidebar.defaultProps = {
  menus: [],
}