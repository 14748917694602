import React from "react";
import { Form } from "formik"
import Input from "../FormikField/Input";
import Button, { Props as ButtonProps } from "../Button";
import { Flex, FlexDirection } from "../Views";
import Select from "../FormikField/Select";
import { Option } from "../Select";

type LayoutItem<T> = T & { flex?: number };

export type FieldUiSchema = { style?: React.CSSProperties } & ({
  widget: 'input';
  name?: string;
  type?: string;
  label?: string;
  autoFocus?: boolean;
} | {
  widget: 'select';
  name?: string;
  label?: string;
  options: Option[];
} | {
  widget: 'button';
  type?: ButtonProps['type'];
  text?: string;
  align?: 'left' | 'right' | 'center';
} | {
  widget: 'layout';
  direction?: 'horizontal' | 'vertical';
  items: Array<LayoutItem<FieldUiSchema>>
})

export type UiSchema = Array<FieldUiSchema>;

type ItemSchema = FieldUiSchema | LayoutItem<FieldUiSchema>

function isLayoutItem(item: ItemSchema, props: DynamicFieldProps): item is LayoutItem<FieldUiSchema> {
  return props.hasOwnProperty('parentDirection');
}

interface DynamicFieldProps {
  schema: ItemSchema
  parentDirection?: 'horizontal' | 'vertical';
}

function DynamicField(props: DynamicFieldProps) {
  let schema: FieldUiSchema = props.schema;

  if (isLayoutItem(props.schema, props)) {
    let { flex, ...rest } = props.schema;
    schema = rest;

    if (props.parentDirection === 'horizontal') {
      if (!schema.style) schema.style = {}
      schema.style.flex = flex || 1;
      schema.style.padding = '0 8px';
    }
  }

  switch (schema.widget) {
    case 'input':
      return <Input key={schema.name} {...schema} />
    case 'select':
      return <Select key={schema.name} {...schema} />
    case 'button': {
      let style = schema.style || {};
      style.display = 'block'
      if (schema.align === 'left' || schema.align === 'center') style.marginRight = 'auto';
      if (schema.align === 'right' || schema.align === 'center') style.marginLeft = 'auto';
      return <Button key={schema.text} {...schema} style={style}>{schema.text}</Button>
    }
    case 'layout': {
      let direction = schema.direction || "horizontal";
      let views = schema.items.map((it, i) => (
        <DynamicField key={i} schema={it} parentDirection={direction} />
      ))

      let style = schema.style || {};
      if (direction === "horizontal") {
        style.margin = '0 -8px';
        return <Flex flexDirection="row" style={style}>{views}</Flex>;
      } else {
        return <div style={style}>{views}</div>
      }
    }
    default:
      return null;
  }
}

export interface Props {
  uiSchema: UiSchema
}

export function DynamicForm(props: Props) {
  return (
    <Form>
      {props.uiSchema.map((it, i) => <DynamicField key={i} schema={it} />)}
    </Form>
  )
}
