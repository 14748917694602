import { Form, FormikProps } from "formik";
import React from "react";
import Button from "../../components/Button";
import FormikInput from "../../components/FormikField/Input";
import Api from "../../helpers/Api";
import { TaxGroup } from "../../helpers/Responses";
import CommonModal, { Props as CommonModalProps } from "./CommonModal";
import setValue from "lodash/set";
import isNumber from "lodash/isNumber";

const btnStyle = { marginTop: '16px', marginLeft: 'auto', display: 'block' }

export function TaxGroupModal(props: Pick<CommonModalProps, 'onSubmit' | 'initialData'>) {
  const isEditMode = !!props.initialData?.id;

  return (
    <CommonModal
      {...props}
      modalId="tax-group"
      title="Tax Group"
      createApi={Api.Masters("tax-groups").create}
      updateApi={Api.Masters("tax-groups").update}
      initialData={props.initialData || {
        name: '',
        hsn: '',
        taxes: {}
      }}
    >
      {(p: FormikProps<TaxGroup>) => {

        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          let values = p.values
          let name = e.target.name;
          let value = e.target.value ? parseFloat(e.target.value) : undefined;
          setValue(values, name, value)
          if (name === "taxes.gst") {
            setValue(values, "taxes.cgst", isNumber(value) ? value / 2 : undefined)
            setValue(values, "taxes.sgst", isNumber(value) ? value / 2 : undefined)
          } else if (name === "taxes.cgst" || name === "taxes.sgst") {
            setValue(values, "taxes.gst", isNumber(value) ? value * 2 : undefined)
            setValue(values, "taxes.cgst", value)
            setValue(values, "taxes.sgst", value)
          }
          p.setValues(values)
        }

        return (
          <Form>
            <FormikInput label="HSN/SAC No" name="hsn" type="number" />
            <FormikInput label="Tax Name" name="name" type="text" />
            <FormikInput label="GST" name="taxes.gst" type="number" onChange={handleChange} />
            <FormikInput label="CGST" name="taxes.cgst" type="number" onChange={handleChange} />
            <FormikInput label="SGST" name="taxes.sgst" type="number" onChange={handleChange} />
            <Button type="submit" style={btnStyle}>{isEditMode ? 'Update' : 'Create'}</Button>
          </Form>
        )
      }}
    </CommonModal>
  )
}