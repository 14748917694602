import React from 'react';
import { useField } from 'formik';
// import { Field, FieldProps, useField } from 'formik';
import FileUpload, { Props } from '../FileUpload';

// function FormikInputComponent(props: FieldProps) {
//   let {
//     field, // { name, value, onChange, onBlur }
//     form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
//     ...rest
//   } = props;

//   const error = touched[field.name] ? errors[field.name] as string : '';

//   const handleChange: Props['onChange'] = async (value, name) => {
//     props.form.setFieldValue(name, value);
//   }

//   function handleBlur(name: string) {
//     props.form.setFieldTouched(name, true);
//   }

//   return <FileUpload {...field} {...rest} error={error} onBlur={handleBlur} onChange={handleChange} />
// }

// export default function FormikInput(props: Props) {
//   return <Field component={FormikInputComponent} {...props} />;
// }

export default function FormikFileUpload(props: Props) {
  const [field, meta, helper] = useField({ name: props.name || '' });

  const handleChange: Props['onChange'] = (value) => {
    helper.setValue(value);
  }

  function handleBlur(name: string) {
    helper.setTouched(true);
  }

  return <FileUpload {...field}  {...props} error={meta.touched && meta.error} onBlur={handleBlur} onChange={handleChange} />
}