import React, { useState, useRef } from 'react';
import Button from '../Button';
import { Label, Error } from '../Typo';
import Api from '../../helpers/Api';
import styled from 'styled-components';
import { UploadPurpose } from '../../helpers/Responses';

const PreviewHolder = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ImageWrapper = styled.div`
  position: relative;
  margin: 0 8px 8px 0;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  font-size: 0;

  &:before {
    content: '';
    position: absolute;
    display: flex;
    transition: 0.5s background;
  }
  &:hover:before {
    content: 'remove';
    font-size: 16px;
    font-weight: 600;
    color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    cursor: pointer;
    transition: 0.3s background;
  }
`;

interface ImageProps {
  src: string | File;
  alt: string;
  style?: React.CSSProperties;
}

const imageStyle = { maxHeight: '100px' };

const Image = React.memo(function Img(props: ImageProps) {
  let src: string;

  if (!props.src) {
    src = 'https://via.placeholder.com/150?text=Image';
  } else if (typeof props.src == 'string') {
    if (props.src.startsWith('http://') || props.src.startsWith('https://'))
      src = props.src
    else
      src = process.env.REACT_APP_BASE_IMG + props.src
  } else {
    src = URL.createObjectURL(props.src)
  }
  return <img src={src} style={props.style || imageStyle} alt={props.alt} />;
});

export interface Props {
  name: string;
  purpose: UploadPurpose;
  text?: string;
  label?: string;
  error?: string | boolean;
  value?: string | string[];
  onChange?: (value: undefined | null | string | string[], name: string) => void | Promise<void>;
  onBlur?: (name: string) => void;
  onFocus?: (name: string) => void;
  multiple?: boolean;
  imageStyle?: React.CSSProperties;
}

export default function FileUpload(props: React.PropsWithChildren<Props>) {
  let { name, value, onChange, onBlur, onFocus, multiple } = props;
  let ref = useRef<HTMLInputElement>(null);
  let isClicked = useRef(false);
  let [loading, setLoading] = useState(false);
  let [filesMap, setFilesMap] = useState<Record<string, File>>({});

  function handleClick() {
    isClicked.current = true;
    if (!ref.current) return;
    ref.current.click();
  }

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    isClicked.current = false;
    if (!event.target.files) return;

    let files = Array.from(event.target.files);
    setLoading(true);

    let now = Date.now();
    let map: Record<string, File> = {};
    files.forEach((f, i) => map[`file-${now}-${i + 1}`] = f)

    // update local keys
    setFilesMap(filesMap => ({ ...filesMap, ...map }));

    // upload & update remote keys
    let formData: FormData = new FormData();
    Object.entries(map).forEach(([k, f]) => formData.append(k, f));
    let res = await Api.upload(formData, props.purpose);
    let imageKeys: string[] = [];
    setFilesMap(filesMap => {
      filesMap = { ...filesMap }
      res.data.forEach(it => {
        if (!!filesMap[it.fieldName] && !filesMap[it.key]) {
          !imageKeys.includes(it.key) && imageKeys.push(it.key);
          filesMap[it.key] = filesMap[it.fieldName];
          delete filesMap[it.fieldName];
        }
      });
      return filesMap;
    });

    if (multiple) {
      let images = Array.isArray(value) ? value : [];
      images = [...images, ...imageKeys];
      onChange && onChange(images, name);
    } else {
      onChange && onChange(imageKeys[0], name);
    }

    if (ref.current) ref.current.value = '';
    setLoading(false);
  }

  function handleRemove(index: number) {
    if (multiple) {
      let images = Array.isArray(value) ? value : [];
      images.splice(index, 1);
      onChange && onChange(images, name);
    } else {
      onChange && onChange(null, name);
    }
  }

  function handleFocus() {
    isClicked.current = false;
    onFocus && onFocus(name);
  }

  function handleBlur() {
    if (!isClicked.current) {
      onBlur && onBlur(name);
    }
  }


  let images = Array.isArray(value) ? value : value ? [value] : [];

  const error = !loading && props.error

  return (
    <div>
      {props.label && <Label>{props.label}</Label>}
      <PreviewHolder>
        {images.map((img, i) => {
          return (
            <ImageWrapper key={img} onClick={() => handleRemove(i)}>
              <Image
                src={img.startsWith('#') ? filesMap[img] : img}
                alt={name}
                style={props.imageStyle}
              />
            </ImageWrapper>
          );
        })}
      </PreviewHolder>
      {(multiple || !value) && (
        <div>
          <input type="file" multiple={multiple} ref={ref} name={name + '-input'} onChange={handleChange} style={{ display: 'none' }} />
          <Button variant="secondary" loading={loading} onClick={handleClick} onFocus={handleFocus} onBlur={handleBlur}>{props.text}</Button>
        </div>
      )}
      {error && <Error>{error}</Error>}
    </div>
  )
}
