import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { StyledSelect as Select } from '../../../components/Select';
import { Order } from '../../../helpers/Responses';
import Api from '../../../helpers/Api';
import Button from '../../../components/Button';
import { Label } from '../../../components/Typo';
import { AppContext } from '../../../context/AppContext';
import { toast } from 'react-toastify';

const StatusField = styled.div` 

`;

const Status = styled.div<{ error?: boolean }>`
  color: ${p => p.error ? p.theme.danger : p.theme.primary};
  font-size: 1.2em;
  font-weight: bold;
`;

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
  justify-content: space-between;

  ${Select}, ${StatusField} {
    flex: 1;
    margin-right: 16px;
  }
`;
Container.displayName = 'StatusPaneContainer';

interface Props {
  order: Order;
}

export default function OrderStatusPane(props: Props) {
  let { state } = useContext(AppContext);

  let { order } = props;

  let [statusLoading, setStatusLoading] = useState(false);
  let [statusSet, setStatusSet] = useState({
    status: order.status,
    payStatus: order.payStatus,
    delStatus: order.delStatus
  });

  useEffect(() => setStatusSet({
    status: order.status,
    payStatus: order.payStatus,
    delStatus: order.delStatus
  }), [order]);


  async function handleUpdateStatus() {
    let msg = '';
    if (statusSet.status === 'completed') {
      if (statusSet.payStatus !== 'paid') msg = 'Unpaid order cannot be Closed';
      if (statusSet.delStatus !== 'delivered') msg = 'Undelivered order cannot be Closed';
    } else if (statusSet.status === 'rejected') {
      if (statusSet.delStatus === 'delivered') msg = 'Delivered order cannot be Rejected';
    }
    if (msg) return toast.error(msg);

    setStatusLoading(true);
    await Api.updateOrderStatus(order.id, statusSet)

    order.status = statusSet.status;
    order.payStatus = statusSet.payStatus;
    order.delStatus = statusSet.delStatus;
    setStatusLoading(false);
  }

  function handleStatusChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setStatusSet({ ...statusSet, [event.target.name]: event.target.value })
  }

  // TODO: repeated in order table
  const closed = ['completed', 'cancelled', 'rejected'].includes(order.status)
  if (!closed) {
    return (
      <Container>
        <Select name="status" label="Order Status" options={state.options.orderStatus} value={statusSet.status} onChange={handleStatusChange} />
        <Select name="payStatus" label="Payment Status" options={state.options.payStatus} value={statusSet.payStatus} onChange={handleStatusChange} />
        <Select name="delStatus" label="Delivery Status" options={state.options.delStatus} value={statusSet.delStatus} onChange={handleStatusChange} />
        <Button onClick={handleUpdateStatus} loading={statusLoading}>Update</Button>
      </Container>
    )
  } else {
    return (
      <Container>
        <StatusField>
          <Label>Order Status</Label>
          <Status error={['cancelled', 'rejected'].includes(order.status)}>
            {state.map.orderStatus[order.status]}
          </Status>
        </StatusField>
        <StatusField>
          <Label>Payment Status</Label>
          <Status error={order.payStatus !== 'paid'}>
            {state.map.payStatus[order.payStatus]}
          </Status>
        </StatusField>
        <StatusField>
          <Label>Delivery Status</Label>
          <Status error={order.delStatus !== 'delivered'}>
            {state.map.delStatus[order.delStatus]}
          </Status>
        </StatusField>
      </Container>
    )
  }
}