import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import { useModalAction } from "../../components/Modal/ModalProvider";
import PageTitleContainer from "../../components/PageTitleContainer";
import Table from "../../components/Table/TableLayout";
import { DataTable, Props as DataTableProps } from "../../components/Table/DataTable";
import { PageTitle } from "../../components/Typo";
import { AxiosResponse } from "axios";
import getValue from "lodash/get"
import { Props as CommonModalProps } from "./CommonModal";
import { DeleteIcon, EditIcon } from "../../components/Icons";


type RenderProp = Pick<CommonModalProps, 'modalId' | 'title' | 'createApi' | 'updateApi' | 'onSubmit' | 'onClose' | 'initialData'>;

export interface Props {
  modalId: string;
  title: string;
  columns: DataTableProps['columns'];
  valueMaps?: DataTableProps['valueMaps'];
  style?: React.CSSProperties;
  listApi: () => Promise<AxiosResponse>;

  /**
   * simply passed to renderCreateModal renderProps
   */
  createApi?: CommonModalProps['createApi'];

  /**
   * simply passed to renderCreateModal renderProps
   */
  updateApi?: CommonModalProps['updateApi'];
  renderCreateModal: (props: RenderProp) => React.ReactNode
  deleteApi: (id: string) => Promise<AxiosResponse>;
}

export default function CommonMaster(props: Props) {
  const { modalId, title, createApi, updateApi } = props;
  let [loading, setLoading] = useState(false);
  let { setModal } = useModalAction(modalId);
  // let [value, setValue] = useState('');
  let [data, setData] = useState<Array<Record<string, any>>>([]);
  let [selectedData, setSelectedData] = useState<Record<string, any>>({});

  useEffect(() => {
    setLoading(true)
    props.listApi().then(res => {
      setData(res.data)
      setLoading(false)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleClose() {
    console.log('handleClose::')
    setSelectedData({});
  }

  async function handleSubmit(values: any, isNew: boolean) {
    if (isNew) {
      console.log('handleSubmit::create');
      setData(data => ([...data, values]));
    } else {
      console.log('handleSubmit::update');
      setData(data => {
        let i = data.findIndex(item => item.id === values.id);
        data[i] = values;
        console.log('handleSubmit::updating', values.id, i, values, data);
        return [...data];
      });
      setSelectedData({});
    }
  }

  function handleEdit(index: number) {
    setSelectedData(data[index]);
    setModal(true);
  }

  async function handleDelete(index: number) {
    // let index = data.findIndex(it => it.id === id);
    let result = window.confirm(`Are you sure want to delete '${data[index].name}'`)
    if (!result) return;

    let res = await props.deleteApi(data[index].id)
    if (res.status === 200) {
      data.splice(index, 1);
      setData([...data])
    }
  }

  console.log('data', data)

  return (
    <>
      <PageTitleContainer>
        <PageTitle>{title} Master</PageTitle>
      </PageTitleContainer>
      <DataTable
        style={props.style}
        columns={props.columns}
        valueMaps={props.valueMaps}
        // columns={[...props.columns, '', '']}
        // columns={[{ display: 'Route Names', slug: 'name' }]}
        // data={routes.map(it => ([it.name]))}
        placeholder={loading ? "Loading .." : `No ${title}`}
        actions={['edit', 'delete']}
        data={data}
        onEdit={handleEdit}
        onDelete={handleDelete}
      >
        {/* {data.map((it, index) => (
          <Table.Row key={it.id}>
            {props.columns.map(col => {
              if (typeof col === 'string')
                return <Table.Cell key='name'>{it.name}</Table.Cell>


              let value = getValue(it, col.slug);
              return <Table.Cell key={col.slug}>{value}</Table.Cell>
              // return typeof col === 'string'
              //   ? <Table.Cell key='name'>{it.name}</Table.Cell>
              //   : <Table.Cell key={col.slug}>{getValue(it, col.slug)}</Table.Cell>
            })}
            <Table.Cell icon>
              <EditIcon onClick={() => handleEdit(index)} />
            </Table.Cell>
            <Table.Cell icon>
              <DeleteIcon onClick={() => handleDelete(it.id)} />
            </Table.Cell>
          </Table.Row>
        ))} */}
      </DataTable>
      <div style={{ marginTop: '8px' }}>
        <Button onClick={() => setModal(true)}>Add New</Button>
        {props.renderCreateModal({
          modalId,
          title,
          createApi,
          updateApi,
          onSubmit: handleSubmit,
          onClose: handleClose,
          initialData: selectedData
        })}
      </div>
    </>
  )
}
