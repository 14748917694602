import styled from "styled-components";
// import { PageTitle } from "./Typo";

const PageTitleContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > *:nth-child(1) {
    margin-right: 16px;
  }
`;
PageTitleContainer.displayName = "PageTitleContainer";

export default PageTitleContainer;
