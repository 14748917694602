import React from "react";
import ReactModal from "react-modal";
import styled from "styled-components";
import Button, { Props as ButtonProps } from "../Button";
import { Flex, AppWrapper } from "../Views";

// You can change the default styles by modifying Modal.defaultStyles
// http://reactcommunity.org/react-modal/styles/
ReactModal.defaultStyles = {}

// portal: ReactModalPortal
// overlay: ReactModal__Overlay ReactModal__Overlay--after-open
// overlay: position: fixed; inset: 0px; background-color: rgba(255, 255, 255, 0.75);
// modal: ReactModal__Content ReactModal__Content--after-open
// modal: position: absolute; inset: 40px; border: 1px solid rgb(204, 204, 204); background: rgb(255, 255, 255); overflow: auto; border-radius: 4px; outline: none; padding: 20px;

const OVERLAY_SELECTOR = '.ReactModal__Overlay';
const MODAL_SELECTOR = '.ReactModal__Content'

const Header = styled.header`
  padding: 16px 16px 0 16px;
  font-weight: bold;
  &.divider {
    padding: 16px;
    border-bottom: 1px solid #ccc;
  }
`;

const Body = styled.div`
  padding: 16px;
`;

const Footer = styled.footer`
  padding: 0 16px 16px 16px;
  &.divider {
    padding: 16px;
    border-top: 1px solid #ccc;
  }
`;

interface FooterButtonViewProps {
  onRequestClose: Props['onRequestClose'];
  buttonProps: FooterButton | string;
}

// function FooterButtonView(props: FooterButtonViewProps) {
//   let { buttonProps } = props;

//   let handleClick = props.onRequestClose;
//   let b: FooterButton;
//   if (typeof buttonProps === "string") {
//     b = { text: buttonProps }
//   } else {
//     b = buttonProps;
//     if (buttonProps.onClick)
//       handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
//         let p: Promise<boolean | void> = Promise.resolve(true);
//         if (buttonProps.onClick) p = Promise.resolve(buttonProps.onClick(e));
//         p.then(status => {
//           if (status === false) return;
//           props.onRequestClose && props.onRequestClose(e);
//         })
//       }
//   }
//   let variant = b.variant || (i === buttonsCount - 1 ? 'primary' : 'secondary');
//   return <Button key={i} variant={variant} onClick={handleClick}>{b.text}</Button>
// }

interface FooterButton {
  text: string;
  variant?: ButtonProps['variant'];
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<boolean | void> | boolean | void;
  closable?: boolean;
}

export interface Props {
  id?: string;
  className?: string;
  modalClassName?: string | ReactModal.Classes;
  overlayClassName?: string | ReactModal.Classes;
  width?: string;
  maxWidth?: string;

  isOpen: boolean;
  onRequestClose?(event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>): void

  title?: string | React.ReactNode;
  header?: React.ReactNode;
  buttons?: Array<FooterButton | string>;
  footer?: React.ReactNode;
  divider?: boolean;
}

function ModalAdapter(props: React.PropsWithChildren<Props>) {
  let { id, className, modalClassName, overlayClassName } = props;

  let overlayStyles: React.CSSProperties = {};
  let contentStyles: React.CSSProperties = {};
  if (props.width) contentStyles.width = props.width;
  if (props.maxWidth) contentStyles.maxWidth = props.maxWidth;

  // let ref = React.useRef(0);
  // console.log('      Modal', id, ++ref.current)

  let footerView = props.footer;
  if (props.buttons && Array.isArray(props.buttons)) {
    const buttonsCount = props.buttons.length;
    const buttonView = props.buttons.map((it, i) => {
      let handleClick = props.onRequestClose;
      let b: FooterButton;
      if (typeof it === "string") {
        b = { text: it }
      } else {
        b = it;
        if (it.onClick)
          handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            let p: Promise<boolean | void> = Promise.resolve(true);
            if (it.onClick) p = Promise.resolve(it.onClick(e));
            p.then(status => {
              if (status === false) return;
              props.onRequestClose && props.onRequestClose(e);
            })
          }
      }
      let variant = b.variant || (i === buttonsCount - 1 ? 'primary' : 'secondary');
      return <Button key={i} variant={variant} onClick={handleClick}>{b.text}</Button>
    });
    footerView = <Flex justifyContent="flex-end">{buttonView}</Flex>
  }


  let classHF = props.divider ? 'divider' : '';
  return (
    <ReactModal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      id={id}
      portalClassName={className}
      className={modalClassName}
      overlayClassName={overlayClassName}
      style={{ overlay: overlayStyles, content: contentStyles }}
      ariaHideApp={false}
    >
      <AppWrapper>
        {props.title && <Header className={classHF}>{props.title}</Header>}
        <Body>{props.children}</Body>
        {footerView && <Footer className={classHF}>{footerView}</Footer>}
      </AppWrapper>
    </ReactModal>
  )
}

export const ModalStyled = styled(ModalAdapter) <Props>`
  & ${OVERLAY_SELECTOR} {
    position: fixed;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & ${MODAL_SELECTOR} {
    background: white;
    outline: none;
    overflow: auto;

    min-width: 300px;
    max-width: 800px;
    border: 1px solid #CCC;
    border-radius: 4px;
  }
  // &[class*='--after-open'] {...}
  // &[class*='--before-close'] {...}
`;
ModalStyled.displayName = 'ModalStyled';

const Modal = React.memo(ModalStyled);
Modal.displayName = 'Modal'
export default Modal;
