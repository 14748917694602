import Ajv from 'ajv';
import { FormikValues } from 'formik';
import set from 'lodash/set';
import get from 'lodash/get';

// export interface FormValues {
//   [name: string]: any | FormValues
// }

export interface ErrorCases {
  default: string;
  required: string;
  minLength: string;
  maxLength: string;
  minimum: string;
  maximum: string;
  pattern: string;
  [keyword: string]: string;
}

export interface MultiError {
  [fieldName: string]: string | Partial<ErrorCases> | MultiError | MultiError[]
}

export function parseValues<T extends FormikValues>(values: FormikValues) {
  let output = { ...values };

  Object.entries(output).forEach(([k, v]) => {
    if (v === "") {
      output[k] = undefined;
    } else if (Array.isArray(v)) {
      output[k] = v.map(it => parseValues(it))
    } else if (typeof v === 'object' && v !== null) {
      output[k] = parseValues(v)
    }
  });
  return output as T;
}

const dotPath = /[.[\]]/;
const idx = /\[\d+\]/g;

export function parseErrors(ajvErrors: Ajv.ErrorObject[] | null | undefined, errorMessages?: MultiError) {
  if (!ajvErrors) return {};

  let errors: Record<string, string | undefined | Object> = {};
  ajvErrors.forEach(e => {
    let params = e.params as Ajv.RequiredParams;

    let name = e.dataPath;
    if (e.keyword === 'required') name = `${e.dataPath}.${params.missingProperty}`
    name = name.substr(1);

    let isNested = dotPath.test(name);

    let msg = e.message;
    if (errorMessages) {
      const fieldError = !isNested ? errorMessages[name] : get(errorMessages, name.replace(idx, '[0]'));
      if (typeof fieldError === 'string') {
        msg = fieldError
      } else if (typeof fieldError === 'object') {
        const fieldErrorCases = fieldError as ErrorCases;
        msg = fieldErrorCases[e.keyword] || fieldErrorCases['default']
      }
    }

    !isNested ? errors[name] = msg : set(errors, name, msg)
  });
  return errors;
}

export function validation(validate: Ajv.ValidateFunction, values: FormikValues, errorMessages?: MultiError) {
  let parsedValues = parseValues(values);
  validate(parsedValues);
  return parseErrors(validate.errors, errorMessages);
}

/**
 * Reference:
 *
 * keyword: "minLength"
 * dataPath: ".name"
 *
 * keyword: "required"
 * dataPath: ""
 * params: {missingProperty: "code"}
 *
 * keyword: "minLength"
 * dataPath: ".variants[0].sku"
 *
 * keyword: "required"
 * dataPath: ".variants[0]"
 * params: {missingProperty: "price"}
 *
 */