import React, { useEffect, useRef, useState } from 'react';
import { DataTable } from '../../components/Table/DataTable';
import { PageTitle } from '../../components/Typo';
import Api from '../../helpers/Api';
import { DashboardReport, Report } from '../../helpers/Responses';
import moment from 'moment';
import { Print } from '../../components/AppComponents';
import { SpaceBetween } from '../../components/Views';
import PageTitleContainer from '../../components/PageTitleContainer';

export default function Dashboard() {

  let [reports, setReports] = useState<DashboardReport>();

  useEffect(() => {
    Api.getDashboard()
      .then(res => setReports(res.data))
  }, [])

  return (
    <>
      <div>
        {reports && reports.map(r => <ReportView key={r.title} report={r} />)}
      </div>
    </>
  );
}

interface ReportProp {
  report: Report
}

function ReportView(props: ReportProp) {
  const { report } = props;
  const ref = useRef<HTMLDivElement>(null);

  let data = report.data.body.map(it => ({ ...it, createdAt: moment(it.createdAt).format('DD MMM, hh:mm A') }))

  return (
    <div ref={ref}>
      <PageTitleContainer>
        <PageTitle>{report.title}</PageTitle>
        <Print content={() => ref.current} />
      </PageTitleContainer>
      <DataTable columns={report.data.head} data={data} />
    </div>
  )
}