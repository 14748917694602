export const productSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
    },
    category: {
      type: "string"
    },
    variants: {
      type: "array",
      items: {
        type: "object",
        properties: {
          sku: {
            type: "string",
          },
          mrp: {
            minimum: 0,
          },
          price: {
            type: "number",
            minimum: 0,
          }
        },
        required: [
          "sku",
          // "price"
        ]
      }
    }
  },
  required: [
    "name",
    // "code",
    // "category",
    "variants"
  ]
}

export const bannerSchema = {
  type: 'array',
  items: {
    type: 'object',
    properties: {
      image: {
        type: 'string'
      }
    },
    required: ['image']
  }
}