import Color from 'color';
import tenantConfig from './data/tenantConfig';
import DataStore from './helpers/DataStore';

// const theme = {
//   primary: '#2684ff',
//   secondary: '#e8e8e8',
//   danger: '#cb2431',

//   selection: '#c3e7fb',
// }

export let variables = {
  primary: '#2684ff',
  secondary: '#d8d8d8', // '#e8e8e8',
  danger: '#cb2431',
}
type Variables = typeof variables;

if (tenantConfig.theme) variables = { ...variables, ...tenantConfig.theme }

export const getTheme = (variables: Variables) => ({
  ...variables,

  // table
  selection: '#c3e7fb',

  button: {
    focus: {
      primary: {
        boxShadow: `0px 0px 0px 4px ${Color(variables.primary).alpha(0.4).rgb()}`,
      },
      secondary: {
        boxShadow: `0px 0px 0px 4px ${Color(variables.secondary).darken(0.05).hex()}`,
      },
      danger: {
        boxShadow: `0px 0px 0px 4px ${Color(variables.danger).alpha(0.4).rgb()}`,
      },
      link: {
        boxShadow: 'none',
        textDecoration: 'underline',
      },
    }
  },

  sidebar: {
    background: '#141d25',

    item: {
      color: '#ccc',
      borderColor: '#444',

      activeParent: {
        color: variables.primary,
      },
      active: {
        background: variables.primary,
        color: 'white',
        boxShadow: '0 1px 5px #395772',
      }
    },
    subitem: {
      background: '#1f2d38',
    }
  },


  // ...lightTheme,
});

export const lightTheme: any = {
  sidebar: {
    background: 'white',

    item: {
      color: 'initial',
      borderColor: '#ddd',

      activeParent: {
        color: variables.primary,
      },
      active: {
        background: variables.primary,
        color: 'white',
        boxShadow: '0 1px 5px #ddd',
      }
    },
    subitem: {
      background: '#eaeded',
    }
  }
}

export const theme = getTheme(variables);
