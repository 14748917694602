import React from "react";
import styled from 'styled-components';
import { StyledIconBase, StyledIcon } from '@styled-icons/styled-icon'
import { Help, HelpOutline, Chat, PowerSettingsNew } from '@styled-icons/material';
import { Chat as ChatOutline } from "@styled-icons/material-outlined/Chat"
import { useHistory } from "react-router-dom";
import { Routes } from "../../helpers/Constants";

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background: white;
`;

const BrandWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 48px;

  img {
    width: auto;
    max-height: 100%;
  }

  span[data-primary] {
    font-size: 24px;
    font-weight: bold;
    color: ${p => p.theme.primary};
  }

  img + span[data-primary] {
    padding-left: 12px;
  }
  
  span[data-secondary] {
    color: #333;
    font-weight: 600;
    border-left: 1px solid #777;
    padding: 6px;
    padding-left: 12px;
    margin-left: 12px;
  }
`;

const RightButtons = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-left: auto;
`;

const MenuContainer = styled.div`
  color: #333;
  padding: 8px 16px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background: #eee;
    color: ${p => p.theme.primary}
  }

  ${StyledIconBase} {
    margin-right: 4px;
    height: 20px;
    color: ${p => p.theme.primary}
  }  
  ${StyledIconBase}[data-state=hover] {
    display: none;
  }
  &:hover {
    ${StyledIconBase}[data-state=normal] {
      display: none;
    }
    ${StyledIconBase}[data-state=hover] {
      display: initial;
    }
  }
`;

interface MenuProps {
  onClick?: () => void;
  icon?: StyledIcon;
  iconActive?: StyledIcon;
}

function Menu(props: React.PropsWithChildren<MenuProps>) {

  let iconView = <></>;
  if (props.icon) {
    if (props.iconActive) {
      iconView = (
        <>
          <props.icon data-state="normal" />
          <props.iconActive data-state="hover" />
        </>
      )
    } else {
      iconView = <props.icon />
    }
  }

  return (
    <MenuContainer onClick={props.onClick}>
      {iconView}
      {props.children}
    </MenuContainer>
  )
}

interface Props {
  brandText?: string;
  brandImg?: string;
}

export default function Header(props: Props) {

  const history = useHistory();

  function handleLogout() {
    window.localStorage.clear();
    history.push(Routes.Login);
  }

  return (
    <Container>
      <BrandWrapper>
        {props.brandImg && <img src={props.brandImg} alt={props.brandText || "Logo"} />}
        <span data-primary>{props.brandText}</span>
        <span data-secondary>Merchant Portal</span>
      </BrandWrapper>
      <RightButtons>
        <Menu icon={ChatOutline} iconActive={Chat}>Chat</Menu>
        <Menu icon={HelpOutline} iconActive={Help}>Help</Menu>
        <Menu icon={PowerSettingsNew} onClick={handleLogout}>Logout</Menu>
      </RightButtons>
    </Container>
  )
}
