import React from 'react';
// import { Field, FieldProps, useField } from 'formik';
import { useField } from 'formik';
import TextArea, { Props } from '../TextArea';

// function FormikInputComponent(props: FieldProps) {
//   let {
//     field, // { name, value, onChange, onBlur }
//     form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
//     ...rest
//   } = props;

//   const error = touched[field.name] ? errors[field.name] as string : '';
//   return <Input {...field} {...rest} error={error} />
// }

// export default function FormikInput(props: Props) {
//   return <Field component={FormikInputComponent} {...props} />;
// }

export default function FormikTextArea(props: Props) {
  const [field, meta] = useField({ name: props.name || '' });
  return <TextArea {...field} {...props} error={meta.touched && meta.error} />
}