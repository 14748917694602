import React, { useEffect, useState } from "react";
import Api from "../../helpers/Api";
import { UiSchema } from "../../components/DynamicForm";
import CommonModal, { Props as CommonModalProps } from "./CommonModal";
import CommonMaster, { Props as CommonMasterProps } from "./CommonMaster";
import defaultAccountGroups from "../../data/default-account-groups.json"
import { Option } from "../../components/Select";
import { toMap, toOptions } from "../../helpers/Utils";
import { TaxGroupModal } from "./TaxGroupModal";

export function AreaMaster() {
  return (
    <CommonMaster
      modalId="area"
      title="Area"
      columns={[{ slug: 'name', display: 'Area Name' }]}
      style={{ width: '400px' }}
      listApi={Api.Masters("areas").list}
      createApi={Api.Masters("areas").create}
      updateApi={Api.Masters("areas").update}
      deleteApi={Api.Masters("areas").delete}
      renderCreateModal={(renderProps) => (<CommonModal {...renderProps} />)}
    />
  );
}

export function RouteMaster() {
  return (
    <CommonMaster
      modalId="route"
      title="Route"
      columns={[{ slug: 'name', display: 'Route Name' }]}
      style={{ width: '400px' }}
      listApi={Api.Masters("routes").list}
      createApi={Api.Masters("routes").create}
      updateApi={Api.Masters("routes").update}
      deleteApi={Api.Masters("routes").delete}
      renderCreateModal={(renderProps) => (<CommonModal {...renderProps} />)}
    />
  );
}

export function TaxGroupMaster() {
  const columns: CommonMasterProps['columns'] = [
    { slug: 'hsn', display: 'HSN/SAC No' },
    { slug: 'name', display: 'Tax Name' },
    { slug: 'taxes.cgst', display: 'CGST' },
    { slug: 'taxes.sgst', display: 'SGST' },
    { slug: 'taxes.gst', display: 'GST' },
  ]

  return (
    <CommonMaster
      modalId="tax-group"
      title="Tax Group"
      columns={columns}
      style={{ width: '600px' }}
      listApi={Api.Masters("tax-groups").list}
      deleteApi={Api.Masters("tax-groups").delete}
      renderCreateModal={(renderProps) => (<TaxGroupModal {...renderProps} />)}
    />
  );
}

export function CompanyMaster() {
  return (
    <CommonMaster
      modalId="manufacturer"
      title="Company"
      columns={[{ slug: 'name', display: 'Company Name' }]}
      style={{ width: '400px' }}
      listApi={Api.Masters("manufacturers").list}
      createApi={Api.Masters("manufacturers").create}
      updateApi={Api.Masters("manufacturers").update}
      deleteApi={Api.Masters("manufacturers").delete}
      renderCreateModal={(renderProps) => (<CommonModal {...renderProps} />)}
    />
  );
}

export function SaltMaster() {
  return (
    <CommonMaster
      modalId="salt"
      title="Group/Salt"
      columns={[{ slug: 'name', display: 'Group/Salt Name' }]}
      style={{ width: '400px' }}
      listApi={Api.Masters("salt").list}
      createApi={Api.Masters("salt").create}
      updateApi={Api.Masters("salt").update}
      deleteApi={Api.Masters("salt").delete}
      renderCreateModal={(renderProps) => (<CommonModal {...renderProps} />)}
    />
  );
}

export function SalesmenModal(props: Pick<CommonModalProps, 'onSubmit'>) {
  const uiSchema: UiSchema = [
    { widget: 'input', label: 'Salesmen Name', name: 'name' },
    { widget: 'input', label: 'Mobile No', name: 'mobile' },
    { widget: 'input', label: 'Commission', name: 'others.commission' },
    { widget: 'input', label: 'Address Line 1', name: 'address.lines[0]' },
    { widget: 'input', label: 'Address Line 2', name: 'address.lines[1]' },
    { widget: 'input', label: 'Address Line 3', name: 'address.lines[2]' },
  ]

  return (
    <CommonModal
      {...props}
      modalId="salesmen"
      title="Salesmen"
      uiSchema={uiSchema}
      createApi={Api.createEmployee}
      updateApi={Api.updateEmployee}
    />
  )
}

export function SalesmenMaster() {
  const columns: CommonMasterProps['columns'] = [
    { display: 'Salesmen Name', slug: 'name' },
    { display: 'Mobile No', slug: 'mobile' },
    { display: 'Commission', slug: 'others.commission' },
    { display: 'Address Line 1', slug: 'address.lines[0]' },
    { display: 'Address Line 2', slug: 'address.lines[1]' },
    { display: 'Address Line 3', slug: 'address.lines[2]' },
  ]
  return (
    <CommonMaster
      modalId="salesmen"
      title="Salesmen"
      columns={columns}
      style={{ maxWidth: '100%' }}
      listApi={Api.listEmployees}
      deleteApi={Api.deleteEmployee}
      renderCreateModal={(renderProps) => (<SalesmenModal {...renderProps} />)}
    />
  );
}

export function CategoryMaster() {
  return (
    <CommonMaster
      modalId="category"
      title="Category"
      columns={[{ slug: 'name', display: 'Category Name' }]}
      style={{ width: '400px' }}
      listApi={Api.Masters("categories").list}
      createApi={Api.Masters("categories").create}
      updateApi={Api.Masters("categories").update}
      deleteApi={Api.Masters("categories").delete}
      renderCreateModal={(renderProps) => (<CommonModal {...renderProps} />)}
    />
  );
}

const optionsCrDr = [
  { label: ' - ', value: '' },
  { label: 'Cr', value: 'CR' },
  { label: 'Dr', value: 'DR' },
]

const optionsStatus = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' }
]

const optionsAccGroups = defaultAccountGroups
  .sort((a, b) => a.name > b.name ? 1 : -1)
  .map(it => ({ label: it.name, value: it.ref }));
optionsAccGroups
  .unshift({ label: ' - ', value: '' })
const defaultAccountGroupsMap = toMap(defaultAccountGroups, 'name', 'ref');
// const defaultAccountGroupsMap = defaultAccountGroups.reduce((prev: Record<string, any>, curr) => {
//   prev[curr.ref] = curr.name;
//   return prev;
// }, {})

// function toValueMap(array: Array<Record<string, any>>, displayKey: string, idKey: string = 'id') {
//   return array.reduce((prev: Record<string, any>, curr) => {
//     prev[curr[idKey]] = curr[displayKey];
//     return prev;
//   }, {})
// }

// interface OptionsOfToOptions {
//   labelKey?: string;
//   valueKey?: string;
//   blank?: boolean | string;
// }

// function toOptions(array: Array<Record<string, any>>, options: OptionsOfToOptions) {
//   let { labelKey = 'name', valueKey = 'id', blank } = options;
//   let output = array.map(it => ({ label: it[labelKey], value: it[valueKey] }))

//   if (blank) output.unshift({ label: ' - ', value: '' });
//   return output;
// }

const optionsBalancingMethod = [
  { label: ' - ', value: '' },
  { label: 'BILL BY BILL', value: 'by-bill' },
  { label: 'ON ACCOUNT', value: 'on-account' }
]

export function LedgerModal(props: Pick<CommonModalProps, 'onSubmit'>) {
  const uiSchema: UiSchema = [
    { widget: 'input', label: 'Ledger Name', name: 'name' },
    { widget: 'select', label: 'Account Group', name: 'accountGroup', options: optionsAccGroups },
    {
      widget: 'layout',
      items: [
        { widget: 'input', label: 'Openning Balance', name: 'openningBalance.amount' },
        { widget: 'select', label: 'Openning Balance', name: 'openningBalance.type', options: optionsCrDr },
      ]
    }
  ]

  return (
    <CommonModal
      {...props}
      modalId="ledger"
      title="Ledger"
      uiSchema={uiSchema}
      createApi={Api.Masters("ledgers").create}
      updateApi={Api.Masters("ledgers").update}
    />
  )
}

export function LedgerMaster() {
  const columns: CommonMasterProps['columns'] = [
    { display: 'Ledger Name', slug: 'name' },
    { display: 'Account Group', slug: 'accountGroup' },
    { display: 'Openning Balance', slug: 'openningBalance.amount' },
    { display: 'Openning Balance', slug: 'openningBalance.type' },
  ]
  return (
    <CommonMaster
      modalId="ledger"
      title="Ledger"
      columns={columns}
      valueMaps={{ accountGroup: defaultAccountGroupsMap }}
      listApi={Api.Masters("ledgers").list}
      deleteApi={Api.Masters("ledgers").delete}
      renderCreateModal={(renderProps) => (<LedgerModal {...renderProps} />)}
    />
  );
}

interface SupplierFormProps {
  onSubmit: CommonModalProps['onSubmit'];
  optionsMaps: {
    routes: Option[];
    areas: Option[];
    salesmen: Option[];
  }
}

export function SupplierModal(props: SupplierFormProps) {
  const uiSchema: UiSchema = [
    {
      widget: 'layout',
      items: [
        {
          widget: 'layout',
          direction: 'vertical',
          items: [
            { widget: 'input', label: 'Ledger Name', name: 'name' },
            { widget: 'input', label: 'Address Line 1', name: 'address.lines[0]' },
            { widget: 'input', label: 'Address Line 2', name: 'address.lines[1]' },
            { widget: 'input', label: 'Address Line 3', name: 'address.lines[2]' },
            { widget: 'input', label: 'Country', name: 'address.country' },
            { widget: 'input', label: 'State Code', name: 'address.state' },
            { widget: 'input', label: 'City', name: 'address.city' },

            { widget: 'select', label: 'Area', name: 'address.area', options: props.optionsMaps.areas },
            { widget: 'select', label: 'Route', name: 'route', options: props.optionsMaps.routes },
          ]
        },
        {
          widget: 'layout',
          direction: 'vertical',
          items: [
            { widget: 'input', label: 'REG No', name: 'company.regNo' },
            { widget: 'input', label: 'DL No', name: 'company.dlNo' },
            { widget: 'input', label: 'GSTIN', name: 'company.gstin' },
            { widget: 'input', label: 'PAN No', name: 'company.panNo' },
            { widget: 'input', label: 'FSSAI No', name: 'company.fssaiNo' },

            { widget: 'select', label: 'Balancing Method', name: 'balancingMethod', options: optionsBalancingMethod },
            { widget: 'input', label: 'Openning Balance Amount', name: 'openningBalance.amount' },
            { widget: 'input', label: 'Openning Balance (Cr/Dr)', name: 'openningBalance.type' },
          ]
        },
        {
          widget: 'layout',
          direction: 'vertical',
          items: [
            { widget: 'input', label: 'Credit Amount', name: 'credit.creditAmount' },
            { widget: 'input', label: 'Credit Bill', name: 'credit.creditBill' },
            { widget: 'input', label: 'Credit Days', name: 'credit.creditDays' },

            { widget: 'input', label: 'Phone 1', name: 'contact.phone[0]' },
            { widget: 'input', label: 'Phone 1', name: 'contact.phone[1]' },
            { widget: 'input', label: 'Email', name: 'email' },
            { widget: 'input', label: 'Mobile', name: 'mobile' },

            { widget: 'select', label: 'Salesmen', name: 'representative', options: props.optionsMaps.salesmen },
            { widget: 'select', label: 'Status', name: 'status', options: optionsStatus },
          ]
        },
      ]
    },
  ];
  // { widget: 'input', label: 'Mobile No', name: 'mobile' },
  // { widget: 'input', label: 'Email', name: 'email' },

  return (
    <CommonModal
      {...props}
      modalId="suppliers"
      title="Supplier"
      width="660px"
      uiSchema={uiSchema}
      createApi={Api.createSupplier}
      updateApi={Api.updateSupplier}
    />
  )
}

export function SupplierMaster() {
  let [state, setState] = useState({
    valueMaps: {
      routes: {},
      areas: {},
      salesmen: {},
    },
    optionsMaps: {
      routes: [] as Option[],
      areas: [] as Option[],
      salesmen: [] as Option[],
    }
  });

  useEffect(() => {
    Promise.all([Api.getRoutes(), Api.getAreas(), Api.listEmployees()]).then(([r1, r2, r3]) => {
      let routes = r1.data;
      let areas = r2.data;
      let salesmen = r3.data;

      let valueMaps = {
        routes: toMap(routes),
        areas: toMap(areas),
        salesmen: toMap(salesmen),
      }

      let optionsMaps = {
        routes: toOptions(routes, { blank: true }),
        areas: toOptions(areas, { blank: true }),
        salesmen: toOptions(salesmen, { blank: true }),
      }

      setState({ valueMaps, optionsMaps })
    })
  }, [])

  const columns: CommonMasterProps['columns'] = [
    { display: 'Name', slug: 'name' },
    { display: 'Mobile No', slug: 'mobile' },
    { display: 'Email', slug: 'email' },
    { display: 'City', slug: 'address.city' },
    { display: 'State', slug: 'address.state' },
    { display: 'Country', slug: 'address.country' },
  ]
  return (
    <CommonMaster
      modalId="suppliers"
      title="Supplier"
      columns={columns}
      style={{ maxWidth: '100%' }}
      listApi={Api.listSuppliers}
      deleteApi={Api.deleteSupplier}
      renderCreateModal={(renderProps) => (<SupplierModal {...renderProps} optionsMaps={state.optionsMaps} />)}
    />
  );
}

