import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ProductsList, { productsListParamsAtom, productsListAtom } from './ProductsList';
import ProductForm from './ProductForm';
import { useResetRecoilState } from 'recoil';

export default function Products() {
  let { path } = useRouteMatch();

  // clear products filter params
  let resetParams = (useResetRecoilState(productsListParamsAtom));
  let resetProducts = (useResetRecoilState(productsListAtom));
  useEffect(() => {
    return () => { resetParams(); resetProducts() }
  }, [resetParams, resetProducts])

  return (
    <Switch>
      <Route path={path + '/'} component={ProductsList} exact />
      <Route path={path + '/:id'} component={ProductForm} />
    </Switch>
  )
}