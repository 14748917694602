import React, { useState, useEffect } from 'react';
import { Formik, FieldArray, Form } from 'formik';
import Table from '../../../components/Table/TableLayout';
import { DataTable, RowItem } from '../../../components/Table/DataTable';
import { Order, LineItem } from '../../../helpers/Responses';
import styled from 'styled-components';
import FormikInput from '../../../components/FormikField/Input';
import ChargeItemView from './ChargeItemView';
import Button from '../../../components/Button';
import Api from '../../../helpers/Api';
import { currency } from '../../../helpers/Utils';

const Wrapper = styled.div`
  ${Table.Cell}:last-child, ${Table.HCell}:last-child {
    text-align: right;
  }
`;

const ECell = styled(Table.Cell)`
  padding: 0px;
  border: 1px solid #cccccc;

  &:hover {
    border: 1px solid #b3b3b3;
  }

  input {
    padding: 12px;
    font-size: 14px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 0;
    border: none;
  }
`;
ECell.displayName = 'EditableCell';

// utils //

const getDisplayName = (li: LineItem) =>
  li.name + (li.variantName ? ` (${li.variantName})` : '')

const calculateTotal = (order: Order) => {
  let total = 0;
  order.lineItems.forEach(li => total += (li.unitPrice * li.qty))
  return currency(total);
}

interface Props {
  order: Order
}

function OrderTable(props: Props) {
  let [isEditing, setIsEditing] = useState(false);

  // const { order } = props;
  let [order, setOrder] = useState(props.order);

  useEffect(() => {
    setIsEditing(false)
    setOrder(props.order);
  }, [props.order, props.order.no])

  async function handleSubmit(values: Order) {
    const { lineItems } = values;
    const data = lineItems.map(li => ({ sku: li.sku, unitPrice: li.unitPrice, qty: li.qty }));
    await Api.editOrder(values.id, { lineItems: data });
    setOrder(values);
    setIsEditing(false);
  }

  // TODO: repeated in order status
  const closed = ['completed', 'cancelled', 'rejected'].includes(order.status)
  if (closed || !isEditing) {
    const lineItemsData = order.lineItems.map((li, i) =>
      ([i + 1, getDisplayName(li), currency(li.unitPrice), li.qty, currency(li.price)])
    );

    const chargeItemsData = order.charges.map((li) => ([li.name, currency(li.value)]));

    return (
      <>
        <Wrapper>
          <DataTable noShadow columns={['No', 'Item Name', 'Unit Price', 'Qty', 'Price']}>
            {lineItemsData.map((li, i) => <RowItem key={i} data={li} />)}
            {chargeItemsData.map((li, i) => <ChargeItemView key={i} data={li} />)}
          </DataTable>
        </Wrapper>
        {/*
        {!closed && (
          <div>
            <Button onClick={() => setIsEditing(true)}>Edit Order</Button>
          </div>
        )}
        */}
      </>
    )
  } else {
    return (
      <Formik initialValues={props.order} onSubmit={handleSubmit}>
        {p => (
          <Form>
            <Wrapper>
              <DataTable noShadow columns={['No', 'Item Name', 'Unit Price', 'Qty', 'Price']}>
                <FieldArray name="lineItems" render={() => (
                  p.values.lineItems.map((li, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>{i + 1}</Table.Cell>
                      <Table.Cell>{getDisplayName(li)}</Table.Cell>
                      <ECell style={{ width: '24.8%' }}><FormikInput type="number" name={`lineItems.${i}.unitPrice`} /></ECell>
                      <ECell style={{ width: '13.4%' }}><FormikInput type="number" name={`lineItems.${i}.qty`} /></ECell>
                      <Table.Cell>{currency(li.unitPrice * li.qty)}</Table.Cell>
                    </Table.Row>
                  ))
                )}
                />
                <ChargeItemView data={['Sub Total', calculateTotal(p.values)]} />
              </DataTable>
            </Wrapper>
            <div>
              <Button type="submit" loading={p.isSubmitting}>Save Order</Button>
              <Button onClick={() => setIsEditing(false)} variant="secondary" disabled={p.isSubmitting}>Cancel Edit</Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default OrderTable;
