import React, { Suspense, useCallback, useContext, useEffect } from 'react';
import Sidebar, { MenuItem } from '../../components/Sidebar'
import Layout from '../../components/Layout'
import Header from './Header';
import { Route } from 'react-router-dom';
import Dashboard from '../Dashboard';
import Products from '../Products';
import Orders from '../Orders';
import Customers from '../Customers';
import Settings from '../Settings';
import { AppActions, AppContext } from '../../context/AppContext';
import Api from '../../helpers/Api';
import DataStore from '../../helpers/DataStore';
import { Routes } from '../../helpers/Constants';
// import RouteMaster from '../RouteMaster';
import { AreaMaster, RouteMaster, CompanyMaster, SaltMaster, CategoryMaster, TaxGroupMaster, SalesmenMaster, SupplierMaster, LedgerMaster } from '../Masters';
import { CategoriesResponse } from '../../helpers/Responses';
import tenantConfig from '../../data/tenantConfig';

// Left Sidebar

const menus: MenuItem[] = [
  {
    name: 'Dashboard',
    link: Routes.Dashboard
  },
  {
    name: 'Master',
    link: Routes.Master,
    subMenus: [
      // AVG
      {
        name: 'Item Master',
        link: Routes.Products
      },
      // {
      //   name: 'Tax/HSN/SAC Master',
      //   link: Routes.TaxGroups
      // },
      {
        name: 'Category Master',
        link: Routes.Categories
      },

      // // APOS
      // {
      //   name: 'Legder Master',
      //   link: Routes.LedgerMaster,
      // },
      // {
      //   name: 'Supplier/Customer Master',
      //   link: Routes.SupplierMaster,
      // },
      // {
      //   name: 'Inventory Master',
      //   link: Routes.InventoryMaster,
      //   subMenus: [
      //     {
      //       name: 'Item Master',
      //       link: Routes.Products
      //     },
      //     {
      //       name: 'Tax/HSN/SAC Master',
      //       link: Routes.TaxGroups
      //     },
      //     {
      //       name: 'Company Master',
      //       link: Routes.CompanyMaster
      //     },
      //     {
      //       name: 'Salt/Brand Master',
      //       link: Routes.SaltMaster
      //     },
      //     {
      //       name: 'Category Master',
      //       link: Routes.Categories
      //     },
      //   ]
      // },
      // {
      //   name: 'Other Master',
      //   link: Routes.OtherMaster,
      //   subMenus: [
      //     {
      //       name: 'Area Master',
      //       link: Routes.AreaMaster
      //     },
      //     {
      //       name: 'Route Master',
      //       link: Routes.RouteMaster
      //     },
      //     {
      //       name: 'Salesmen Master',
      //       link: Routes.SalesmenMaster
      //     },
      //   ]
      // },
    ]
  },
  {
    name: 'Orders',
    link: Routes.Orders
  },
  // {
  //   name: 'Transactions',
  //   link: Routes.Transactions,
  //   subMenus: [
  //     {
  //       name: 'Sales Order',
  //       link: Routes.SaleOrders
  //     },
  //     {
  //       name: 'Sales Return',
  //       link: Routes.SaleReturns
  //     },
  //     {
  //       name: 'Purchase Order',
  //       link: Routes.PurchaseOrders
  //     },
  //     {
  //       name: 'Purchase Return',
  //       link: Routes.PurchaseReturns
  //     },
  //   ]
  // },
  // {
  //   name: 'Products',
  //   link: Routes.Products
  // },
  // {
  //   name: 'Orders',
  //   link: Routes.Orders
  // },
  {
    name: 'Customers',
    link: Routes.Customers
  },
  {
    name: 'Settings',
    link: Routes.Settings
  },
]

const brandImg = `${process.env.REACT_APP_BASE_IMG}/${DataStore.getTenant()}/branding`;

const footNote = [`AgamVanigam ${process.env.REACT_APP_VERSION}`, 'Powered by AgamWorks'];

export default function AppLayout() {

  const { dispatch } = useContext(AppContext);
  const stableDispatch = useCallback(dispatch, []);

  useEffect(() => {
    // const p1 = Api.getConfig();
    // const p2 = Api.getCategories();
    // // const p2 = Api.Masters<CategoriesResponse>("categories").list()

    // const promises = [
    //   Api.getConfig(),
    //   Api.getCategories(),
    //   Api.Masters('manufacturers').list(),
    //   Api.Masters('tax-groups').list(),
    //   Api.Masters('salt').list(),
    // ]

    // Promise.all(promises).then(([r1, r2, r3, r4, r5]) => {
    //   let configs = r1.data;
    //   stableDispatch(AppActions.LoadConfig, configs);

    //   let categories = r2.data;
    //   DataStore.setCategories(categories);

    //   let manufacturers = r3.data;
    //   DataStore.setManufacturers(manufacturers);

    //   let taxGroups = r4.data;
    //   DataStore.setTaxGroups(taxGroups);

    //   let saltGroups = r5.data;
    //   DataStore.setSaltGroups(saltGroups);
    // })

    const promises = [
      Api.getConfig().then(res => {
        stableDispatch(AppActions.LoadConfig, res.data);
      }),
      Api.getCategories().then(res => {
        DataStore.setCategories(res.data);
      }),
    ]
    Promise.all(promises);

  }, [stableDispatch])

  return (
    <Layout.Container>
      <Layout.Header>
        {/* <Header brandImg={brandImg + '/logo.png'} brandText="AGAM POS" /> */}
        <Header brandText={tenantConfig.name} brandImg="/temp/logo.png" />
      </Layout.Header>
      <Layout.Left>
        <Sidebar menus={menus} footNote={footNote} />
      </Layout.Left>
      <Layout.Main>
        <Suspense fallback="Loading page ..">
          <Route exact path={Routes.Dashboard} component={Dashboard} />

          <Route path={Routes.LedgerMaster} component={LedgerMaster} />
          <Route path={Routes.SupplierMaster} component={SupplierMaster} />

          <Route path={Routes.Products} component={Products} />
          <Route path={Routes.TaxGroups} component={TaxGroupMaster} />
          <Route path={Routes.CompanyMaster} component={CompanyMaster} />
          <Route path={Routes.SaltMaster} component={SaltMaster} />
          <Route path={Routes.Categories} component={CategoryMaster} />

          <Route path={Routes.AreaMaster} component={AreaMaster} />
          <Route path={Routes.RouteMaster} component={RouteMaster} />
          <Route path={Routes.SalesmenMaster} component={SalesmenMaster} />

          <Route path={Routes.Orders} component={Orders} />
          {/* <Route path={Routes.SaleOrders} component={Orders} /> */}
          <Route path={Routes.Customers} component={Customers} />
          <Route path={Routes.Settings} component={Settings} />
        </Suspense>
      </Layout.Main>
      {/* <Layout.Right>Right Sidebar</Layout.Right> */}
      {/* <Layout.Footer>Footer</Layout.Footer> */}
    </Layout.Container>
  );
}
