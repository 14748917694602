import styled from "styled-components"
import tw from "twin.macro";

/* Label, Error, Hint */
export const Label = styled.label`
  font-size: 14px;
  // margin-top: 4px;
  margin-bottom: 2px;
  display: inline-block;
`;
Label.displayName = "Label";

export const Error = styled(Label)`
  margin-top: 4px;
  color: red;
`;
Error.displayName = "Error";

/* Headings */
export const PageTitle = tw.h2`text-2xl font-bold`;
