import { FormikProps } from "formik";

/* * * * * * * * * * * * * * * * * * * */
/* * * * * * Formik Utils  * * * * * * */
/* * * * * * * * * * * * * * * * * * * */


export function getFieldProps<T extends Record<string, any>>(props: FormikProps<T>, name: string) {
  return {
    name,
    value: props.values[name],
    error: props.touched[name] && props.errors[name],
    onChange: props.handleChange,
    onBlur: props.handleBlur
  }
}

/* * * * * * * * * * * * * * * * * * * */
/* * * * * Application Utils * * * * * */
/* * * * * * * * * * * * * * * * * * * */

export function getTenantFromHost(hostname: string) {
  let isDomainMatched =
    hostname.endsWith("agamvanigam.com") || hostname.endsWith("avg.local");

  // eg. kyrios.merchant-dev.agamvanigam.com
  if (isDomainMatched) {
    let split = hostname.split(".").reverse();
    let verifiedSubDomain =
      split[2].startsWith("merchant") || split[2].startsWith("customer");

    if (split.length === 4 && verifiedSubDomain) return split[3];
  }

  const devHost = [
    "localhost",
    "avg-web-dev.herokuapp.com",
    "agam-vanigam-dev.firebaseapp.com",
    "agam-vanigam-dev.web.app",
    "avg-admin.rambabusaravanan.com"
  ];
  if (devHost.includes(hostname)) {
    return "market4home";
    // return "main";
  }

  return "market4home"; // error
}


export function removeMobileCode(mobile?: string) {
  return mobile ? mobile.split(' ')[1] : '';
}

export function currency(number: number) {
  if (typeof number !== 'number') return '';
  return '₹' + number.toFixed(2)
}

/* * * * * * * * * * * * * * * * * * * */
/* * *  String / Number Operations * * */
/* * * * * * * * * * * * * * * * * * * */

/**
 * Generate OTP (random numbers)
 * @param {number} len default 4, maximum 15
 */
export function getRandomNumber(len = 4) {
  return Math.random().toString().substr(2, len);
}

/**
 * Generate random characters. default alpha numeric of length 9
 */
interface RandomCharsOptions {
  alpha?: boolean;
  small?: boolean;
  caps?: boolean;
  num?: boolean;
  special?: string;
}
export function generateChars(length = 9, options?: RandomCharsOptions) {
  const s = 'abcdefghijklmnopqrstuvwxyz';
  const c = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const n = '0123456789';

  let chars = '';
  if (!options) {
    chars = c + s + n;
  } else {
    if (options.alpha || options.caps) chars += c;
    if (options.alpha || options.small) chars += s;
    if (options.num) chars += n;
    if (options.special) chars += options.special;
  }

  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

/* * * * * * * * * * * * * * * * * * */
/* * * Object / Array Operations * * */
/* * * * * * * * * * * * * * * * * * */

export function findDuplicateIndex(array: Array<string | number | undefined | null>) {
  let map: Record<string, number> = {};
  for (let i = 0; i < array.length; i++) {
    let key = String(array[i]);
    if (key && map[key] !== undefined) return i;
    map[key] = i;
  }
  return -1;
}

interface SlugItem {
  slug: string;
  name: string;
}

export const slugToOptions = (array: SlugItem[]) => array.map(it => ({ label: it.name, value: it.slug }))

export const slugToMap = (array: SlugItem[]) => {
  const map: Record<string, string> = {};
  array.forEach(it => map[it.slug] = it.name);
  return map;
}

// interface IdItem {
//   id: string;
//   name: string;
// }

// export const toOptions = (array: IdItem[]) => array.map(it => ({ label: it.name, value: it.id }))

// export const toMap = (array: IdItem[]) => {
//   const map: Record<string, string> = {};
//   array.forEach(it => map[it.id] = it.name);
//   return map;
// }

interface ToOptions_Options {
  labelKey?: string;
  valueKey?: string;
  blank?: boolean | string;
}

export function toOptions(array: Array<Record<string, any>>, options?: ToOptions_Options) {
  let { labelKey = 'name', valueKey = 'id', blank } = options || {};
  let output = array.map(it => ({ label: it[labelKey], value: it[valueKey] }))

  if (blank) {
    let text: string = (typeof blank === 'string') ? blank : ' - ';
    output.unshift({ label: text, value: '' });
  }
  return output;
}

export function toMap(array: Array<Record<string, any>>, valueKey: string = 'name', idKey: string = 'id') {
  return array.reduce((prev: Record<string, any>, curr) => {
    prev[curr[idKey]] = curr[valueKey];
    return prev;
  }, {})
}

/* * * * * * * * * * * * * */
/* * * File Operations * * */
/* * * * * * * * * * * * * */

export function download(text: string, filename: string, mimetype: string) {
  // const url = window.URL.createObjectURL(blob);
  const content = `data:${mimetype};charset=utf-8,${text}`;
  const uri = encodeURI(content);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = uri;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  // window.URL.revokeObjectURL(url);
}