import React from 'react';
import styled from 'styled-components';
import { Order } from '../../../helpers/Responses';
import OrderTable from './OrderTable';
import OrderStatusPane from './OrderStatusPane';
import { OrderHead } from './OrderHead';

const Container = styled.div`
  background: white;
  margin-left: 32px;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px 1px #bbb;
`;


interface Props {
  order: Order;
}

export default function OrderDetail(props: Props) {
  const { order } = props;

  return (
    <Container>
      <OrderStatusPane order={order} />
      <OrderHead order={order} />
      <OrderTable order={order} />
      {order.payment?.order &&
        <p style={{ fontSize: '12px' }}>
          Payment Receipt ID: {order.payment?.order?.receipt}
        </p>
      }
    </Container>
  )
}

