import styled from "styled-components";

export const FieldWrapper = styled.div`
  margin-bottom: 16px;

  :last-child {
    margin: 0;
  }
`;
FieldWrapper.displayName = 'FieldWrapper';
