import { StorageKeys as Keys } from "./Constants";
import {
  Category,
  Manufacturer,
  Product,
  SaltGroup,
  TaxGroup,
} from "./Responses";
import { getTenantFromHost } from "./Utils";

function clearAll() {
  window.localStorage.clear();
}

function getItem<T = string>(key: string, defaultValue?: T) {
  const value = window.localStorage.getItem(key);
  if (!value && defaultValue) return defaultValue;
  if (!value) return value;

  try {
    return JSON.parse(value) as T;
  } catch (e) {
    return value;
  }
}

function setItem(key: string, value: any) {
  try {
    if (typeof value !== "string") value = JSON.stringify(value);
  } catch (e) { }
  window.localStorage.setItem(key, value);
}

// Specific

function getTenant() {
  // get from storage
  let tenant = getItem(Keys.Tenant);
  if (tenant) return tenant;

  const hostname = window.location.hostname;
  return getTenantFromHost(hostname);
}

const DataStore = {
  clearAll,
  getItem,
  setItem,

  getToken: () => getItem(Keys.Token),
  setToken: (token: string) => setItem(Keys.Token, token),
  clearToken: () => window.localStorage.removeItem(Keys.Token),

  getLandingZone: () => getItem(Keys.LandingZone),
  setLandingZone: (landingZone: string) => setItem(Keys.LandingZone, landingZone),

  // getProducts: () => getItem<Record<string, Product>>(Keys.Products),
  // setProducts: (data: Record<string, Product>) => setItem(Keys.Products, data),

  getTenant,
  setTenant: (tenant?: string) => setItem(Keys.Tenant, tenant),

  // getProducts: () => getItem('products', []) as Product[],
  // setProducts: (products: Product[]) => setItem('products', products),

  getCategories: () => getItem(Keys.Categories, []) as Category[],
  setCategories: (categories: Category[]) =>
    setItem(Keys.Categories, categories),

  getManufacturers: () => getItem("manufacturers", []) as Manufacturer[],
  setManufacturers: (manufacturers: Manufacturer[]) =>
    setItem("manufacturers", manufacturers),

  getTaxGroups: () => getItem("tax-groups", []) as TaxGroup[],
  setTaxGroups: (taxGroups: TaxGroup[]) => setItem("tax-groups", taxGroups),

  getSaltGroups: () => getItem("salt", []) as SaltGroup[],
  setSaltGroups: (salt: SaltGroup[]) => setItem("salt", salt),
};

export default DataStore;
