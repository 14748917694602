import React, { useState } from 'react';
import Input from '../../components/Input';
import styled from 'styled-components';
import Select, { Option } from '../../components/Select';
import { toOptions } from '../../helpers/Utils';
import DataStore from '../../helpers/DataStore';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
`;

export interface CustomersFilterParams {
  search: string;
  group: string;
}

interface Props {
  params?: CustomersFilterParams;
  options: Option[];
  onChange?: (value: CustomersFilterParams) => void;
}

export default function CustomersFilterPane(props: Props) {
  const { params, onChange } = props;

  function handleChangeEvent(event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    handleChange(event.target.value, event.target.name);
    console.log(event.target.value, event.target.name);
  }

  function handleChange(value: string | number | undefined, name: string) {
    let p = params || {} as CustomersFilterParams;
    value = value ? value.toString() : '';
    onChange && onChange({ ...p, [name]: value + '' })
  }

  return (
    <Container>
      <Input
        name="search"
        placeholder="Search (Name or Mobile or Email)"
        style={{ flex: '0 1 500px' }}
        value={params?.search}
        onChange={handleChangeEvent}
      />
      <div style={{ width: '200px' }}>
        <Select
          label="Customer Group"
          name="group"
          options={props.options}
          value={params?.group}
          onChange={handleChangeEvent}
        />
      </div>
    </Container>
  )
}